import React from 'react'
import Typography from '~/components/atoms/Typography'
import { Link } from 'gatsby'
import {
  no__svg,
  facility_features__wrapper,
  facility_features__no__image,
  facility_features__title,
  facility_features__block,
  facility_features__block__icon,
  facility_features__block__image,
  facility_features__block__content,
  facility_features__block__title,
  facility_features__block__body,
  facility_features__supplement__text,
  facility_features__block__none__data
} from './styles'
import { Container } from '@material-ui/core'
import NO_1 from '~/images/sento/1.png'
import NO_2 from '~/images/sento/2.png'
import NO_3 from '~/images/sento/3.png'
import DEFAULT_FF from '~/images/sento/default-ff.jpg'


import { BathhouseType } from 'types'
export type Props = {
    data: BathhouseType
}

const FacilityFeatures: React.VFC<Props> = ({ data }) => {
  const featuresType = data.features.some((val) => {return val})
  const featuresImageType = data.featuresImages.some((val) => {return val})
   return (
    <div css={facility_features__wrapper}>
      {(featuresType || featuresImageType)&&
        <Typography variant="h4" component="h4" color="textPrimary" css={facility_features__title}>
          浴湯施設の特徴
        </Typography>
      }

      {(data.featuresImages[0] || data.features[0]) &&
        <div css={facility_features__block}>
        <div css={facility_features__block__icon}>
          <img src={NO_1} css={no__svg} />
        </div>

        <div css={facility_features__block__image}>
          {data.featuresImages[0] ?
            <img src={data.featuresImages[0]} css={facility_features__no__image} /> :
            (!data.features[0] &&
              <img src={DEFAULT_FF} css={facility_features__no__image} /> 
            )
          }
        </div>

        { data.features[0] ?
          <div css={facility_features__block__content}>
            <div css={facility_features__block__title}>
              { data.features[0].title}
            </div>
            <div css={facility_features__block__body}>
              { data.features[0].body}
            </div>
          </div>
          :
          <div css={facility_features__block__none__data}>
            <p>情報なし</p>
            <p css={facility_features__supplement__text}>※実際の店舗の写真ではありません。 </p>
          </div>
        }
      </div>
      }

      {(data.featuresImages[1] || data.features[1]) &&
        <div css={facility_features__block}>
          <div css={facility_features__block__icon}>
            <img src={NO_2} css={no__svg} />
          </div>

          <div css={facility_features__block__image}>
            {data.featuresImages[1] ?
              <img src={data.featuresImages[1]} css={facility_features__no__image} /> :
              (!data.features[1] &&
                <img src={DEFAULT_FF} css={facility_features__no__image} /> 
              )
            }
          </div>

          { data.features[1] ?
            <div css={facility_features__block__content}>
              <div css={facility_features__block__title}>
                { data.features[1].title}
              </div>
              <div css={facility_features__block__body}>
                { data.features[1].body}
              </div>
            </div>
            :
            <div css={facility_features__block__none__data}>
              <p>情報なし</p>
              <p css={facility_features__supplement__text}>※実際の店舗の写真ではありません。 </p>
            </div>
          }
        </div>
      }

      {(data.featuresImages[2] || data.features[2]) &&
        <div css={facility_features__block}>
        <div css={facility_features__block__icon}>
          <img src={NO_3} css={no__svg} />
        </div>

        <div css={facility_features__block__image}>
          {data.featuresImages[2] ?
            <img src={data.featuresImages[2]} css={facility_features__no__image} /> :
            (!data.features[2]! &&
              <img src={DEFAULT_FF} css={facility_features__no__image} /> 
            )
          }
        </div>

        { data.features[2] ?
          <div css={facility_features__block__content}>
            <div css={facility_features__block__title}>
              { data.features[2].title}
            </div>
            <div css={facility_features__block__body}>
              { data.features[2].body}
            </div>
          </div>
          :
          <div css={facility_features__block__none__data}>
            <p>情報なし </p>
            <p css={facility_features__supplement__text}>※実際の店舗の写真ではありません。</p>
          </div>
        }
      </div>
      }
    </div>
  )
}

export default FacilityFeatures
