import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const official__sns__share___button = css`
  display: flex;
  color: ${palette.text.primary};
  border-color: #D3D3D3;
  font-weight: 800;
  padding: 4px 8px;
  margin: 4px;
  min-height: initial;
`

export const official__sns__sns__svg = css`
  margin-right: 4px;
  margin-bottom: -2px;
  width: 16px;
  height: 16px;
`

export const official__sns__share = css`
  display: flex;
`

export const official__sns__wrapper = css`
  display: flex;
`

export const pc_display_none = css`
  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`
export const official__sns__inquiry = css`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.6rem;
  color: ${palette.common.black};
  margin-top: 20px;
`

export const official__sns__span = css`
  color: ${palette.primary.dark};
  :hover {
    color: ${palette.primary.light};
  }
`
