import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const name = css`
  font-style: normal;
  font-weight: bold;
  font-size: 4.0rem;
  line-height: 51px;
  @media (max-width: ${breakpoints.tablet}px) {
    font-size: 36px;
    line-height: 51px;
  }
`

export const info = css`
  display: flex;
  @media (max-width: ${breakpoints.tablet}px) {
    margin: 5px 0;
  }
`
export const hours = css`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.6rem;
  @media (max-width: ${breakpoints.tablet}px) {
    font-size: 1.4rem;
  }
`

export const primary__color = css`
  color: #3CB7A9;
`

export const tag__container = css`
  display: flex;
  @media (max-width: ${breakpoints.tablet}px) {
    margin: 0 0 3px auto;
  }
`

export const info__wrapper = css`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
  }
`

export const inquiry = css`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.6rem;
  color: ${palette.common.black};
  @media (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`

export const span = css`
  color: ${palette.primary.dark};
  :hover {
    color: ${palette.primary.light};
  }
`

export const tag__wrapper = css`
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
`

export const normal__tag = css`
    text-align: center;
    border-radius: 12px;
    border: 1px solid ${palette.primary.main};
    min-width: 68px;
    height: 24px;
    margin: 0 2px;
    padding: 2px 8px;
    font-size: 1.2rem;
    color: ${palette.primary.main};
`

export const grad__tag = css`
    text-align: center;
    border-radius: 12px;
    width: 68px;
    height: 24px;
    margin: 0 2px;
    padding: 3px 0;
    font-size: 1.2rem;
    color: white;
    background: linear-gradient(225deg, ${palette.primary.light} 0%, ${palette.primary.dark} 100%);
`

export const button = css`
  color: ${palette.primary.dark};
  border-color: #D3D3D3;
  font-weight: 400;
  padding: 8px;
  margin: 4px;
`

export const address__text = css`
  margin-top: 16px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 2.0rem;
  line-height: 2.6rem;
  @media (max-width: ${breakpoints.tablet}px) {
    margin-bottom: 0;
    font-size: 1.8rem;
  }
`

export const ruby = css`
  margin-top: 6px;
  margin-bottom: 17px;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.6rem;
  @media (max-width: ${breakpoints.tablet}px) {
    margin-top: 0;
    font-size: 1.4rem;
    line-height: 2.6rem;
  }
`

export const open__time = css`
  display: flex;
  margin: 0 12px;
  @media (max-width: ${breakpoints.tablet}px) {
    margin: 0 5px;
  }
`

export const share___button__wrapper = css`
  display: flex;
  margin: 16px 0;
  @media (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`

export const share___button = css`
  display: flex;
  color: ${palette.text.primary};
  border-color: #D3D3D3;
  font-weight: 800;
  padding: 8px;
  margin: 4px;
`

export const info__container = css`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${breakpoints.tablet}px) {
    margin-top: -44px;
    display: block;
  }
`

export const sns__svg = css`
  margin-right: 4px;
  margin-bottom: -2px;
  width: 16px;
  height: 16px;
`

export const info__svg__wrapper = css`
  height: 26px;
  display: flex;
  align-items: center;
`

export const info__svg = css`
  width: 20px;
  height: 20px;
`

export const share = css`
  display: flex;
`

export const map__img = css`
  width: 100px;
  height: 76px;
  border-radius: 10px;
  object-fit: cover;
  @media (max-width: ${breakpoints.tablet}px) {
    width: 80px;
    height: 60px;
  }
`

export const map__container = css`
  margin-top: 52px;
  margin-left: 20px;
  height: 100%;
  display: inline-block;
  border: 1px solid ${palette.text.secondary};
  border-radius: 10px;
  :hover {
    border: 1px solid ${palette.primary.main};
    filter: opacity(70%);
  }
  @media (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`

export const map__container__sp = css`
  height: 100%;
  display: inline-block;
  border: 1px solid ${palette.text.secondary};
  border-radius: 10px;
  :hover {
    border: 2px solid ${palette.primary.main};
    filter: opacity(40%);
  }
  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`

export const info__wrapper__sp = css`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
`

export const map__button = css`
  color: ${palette.primary.dark};
  text-align: center;
  padding-top: 2px;
  height: 24px;
  background-color: white;
  border-top: 1px solid ${palette.text.secondary};
  border-radius: 0 0 10px 10px;
`

export const pc_display_none = css`
  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`

export const sp_display_none = css`
  @media (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`

export const info__scroll = css`
  @media (max-width: ${breakpoints.tablet}px) {
    font-size: 1.2rem;
    font-weight: 400;
    margin-left: 26px;
  }
`

export const info__scroll__text = css`
  display: flex;
  align-items: center;
`

export const info__scroll__allow__down = css`
  width: 10px;
  height: 26px;
  margin-left: 3px;
`
