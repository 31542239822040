import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const facility__equipment__wrapper = css `
  color: ${palette.common.black};
  display: inline-block;
`

export const facility__equipment__title = css`
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    letter-spacing: 0.035em;
    line-height: 2.6rem;
    margin-bottom: 27px;
    @media (max-width: ${breakpoints.tablet}px) {
      font-size: 2.0rem;
    }
`

export const list__flex_box = css`
    display: flex;
    flex-wrap: wrap;
`

export const check__svg = css`
    width: 15px;
    height: 26px;
    margin-right: 10px;
`

export const cross__svg = css`
    width: 14px;
    height: 26px;
    margin-right: 10px;
`

export const triangle__svg = css`
    width: 17px;
    height: 26px;
    margin-right: 10px;
`

export const none__svg = css`
  width: 14px;
  height: 26px;
  margin-right: 10px;
`

export const list__item_text = css`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.6rem;
`

export const list__item = css`
    display: flex;
    align-items: center;
    width: 50%;
    float: left;
    @media (max-width: ${breakpoints.tablet}px) {
        // phone
        width: 100%;
    }
`

export const remark = css`
    font-weight: 400;
    margin-top: 25px;
    white-space: pre-wrap;
    line-height: 20px;
    clear: both;
    display: inline-block;
`
