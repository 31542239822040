import React, { useState } from 'react'
import Typography from '~/components/atoms/Typography'
import { summarize } from '~/utils/seo'

import {
  root,
  header,
  title,
  notification_div,
  notification_text,
  notification_text_omit,
  update_at,
  expansion_div,
  expansion_text,
  expansion_text_phone,
} from './styles'
import { breakpoints } from '~/utils/styles'

export type Props = {
  message: string
  updateAt: string
}

const ONE_LINE_CHARS_PC = 26
const ONE_LINE_CHARS_TABLET = 31
const ONE_LINE_CHARS_PHONE = 16

const NotificationMessage: React.VFC<Props> = ({ message, updateAt }) => {

  const [isOmit, setIsOmit] = useState<boolean>(true)

  const date = new Date(updateAt)
  const updateAtString = `更新:${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`

  const getOneLineChars = () => {
    if (typeof window !== 'undefined') {
      if (breakpoints.tablet >= window.innerWidth) return ONE_LINE_CHARS_PHONE
      if (breakpoints.pc >= window.innerWidth) return ONE_LINE_CHARS_TABLET
    }
    return ONE_LINE_CHARS_PC
  }

  // 4行以上となるか検証
  const isMoreThenThreeLines = (): boolean => {
    return message.length / getOneLineChars() > 3
  }

  const getMessage = (message: string) => {
    if (isOmit && isMoreThenThreeLines()) {
      return summarize(message, getOneLineChars() * 3 - 6).split('\n')
        .map((row, index, array) => {
          if (array.length - 1 === index) return <span key={index}>{row}</span>
          return <span key={index}>{row}<br /></span>
        })
    }
    return message.split('\n')
      .map((row, index, array) => {
        if (array.length - 1 === index) return <span key={index}>{row}</span>
        return <span key={index}>{row}<br /></span>
      })
  }

  const getNotificationTextStyle = () => isOmit && isMoreThenThreeLines() ? [notification_text, notification_text_omit] : [notification_text]

  return (
    <div css={root}>
      <div css={header}>
        <Typography variant="h4" component="h4" color="textPrimary" css={title}>
          施設からのお知らせ
        </Typography>
        <p css={update_at}>{updateAtString}</p>
      </div>
      <div css={notification_div}>
        <p css={getNotificationTextStyle()}>
          {message === '' ?
            <span>現在、お知らせはありません。</span>
            :
            <span>
              {getMessage(message)}
              {/* 全て表示ボタン／タブレット・SP版 */}
              {
                isOmit && isMoreThenThreeLines() &&
                <span
                  css={[expansion_text, expansion_text_phone]}
                  onClick={() => setIsOmit(false)}
                >
                  全て表示
                </span>
              }
            </span>
          }
        </p>

      </div>
      {/* 全て表示ボタン／PC版 */}
      {
        isOmit && isMoreThenThreeLines() &&
        <div css={expansion_div}>
          <p
            css={expansion_text}
            onClick={() => setIsOmit(false)}
          >
            全て表示
          </p>
        </div>
      }
    </div>
  )
}

export default NotificationMessage
