import { css } from '@emotion/core'

import { breakpoints } from '~/utils/styles'

export const allows_container = css`
  padding: 0 85px;
  height: 54px;

  // 両端配置
  display: flex;
  justify-content: space-between;

  // 上下中央寄せ
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: ${breakpoints.pc}px) {
    // tablet
    padding: 0 2.4%;
  }
`

export const allow_button = css`
  background-color: rgba(0, 0, 0, 0.52);
  width: 49px;
  height: 54px;
  border-radius: 11px;
  cursor: pointer;

  // 中央寄せ
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoints.pc}px) {
    // tablet
    width: 40px;
    height: 44px;
    border-radius: 10px;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    // phone
    width: 26px;
    height: 29px;
    border-radius: 6px;
  }
`

export const allow_icon = css`
  color: #FFFFFF;
  font-size: 2.5rem;

  @media (max-width: ${breakpoints.pc}px) {
    // tablet
    font-size: 2.0rem;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    // phone
    font-size: 1.5rem;
  }
`

export const slide_item = css`
  padding: 0 1.5px;
  margin: 0;
  position: relative;

  // 上下中央寄せ
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.pc}px) {
    // tablet
    padding: 0;
  }
`

export const image = css`
  width: 100%;
  height: 526px;
  object-fit: cover;

  @media (max-width: ${breakpoints.tablet}px) {
    // phone
    height: 281px;
  }
`

export const caption_tile = css`
  width: calc(100% - 3px);
  height: 12%;

  background-color: rgba(0, 0, 0, 0.47);

  // 下寄せ
  position: absolute;
  bottom: 0;

  // 上下中央寄せ
  display: flex;
  align-items: center;

  padding-left: 42px;

  @media (max-width: ${breakpoints.pc}px) {
    // tablet
    padding-left: 22px;
    padding-right: 22px;
    width: 100%;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    // tablet
    height: 18%;
  }
`

export const caption_typography = css`
  color: #FFF;
  font-size: 1.2em;
  font-weight: 500;
  @media (max-width: ${breakpoints.pc}px) {
    // tablet
    font-size: 0.9em;
  }
`
