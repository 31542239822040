import { css } from '@emotion/core'
import { SvgIcon } from '@material-ui/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const list__container = css`
    border: 1px solid #F0F3F5;
    border-radius: 8px;
`

export const container = css`
    @media (max-width: ${breakpoints.pc}px) {
      // tablet
      margin: 0 auto;
      width: 500px;
    }

    @media (max-width: ${breakpoints.tablet}px) {
      // tablet
      margin: 0 auto;
      width: initial;
    }
`

export const title = css`
    display: flex;
    background-color: #F0F3F5;
    height: 52px;
    padding: 10px;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #F0F3F5;
    align-items: center;
`

export const fee__title = css`
  font-size: 1.8rem;
  margin-left: 10px;

  @media (max-width: ${breakpoints.tablet}px) {
    // phone
    margin-left: 0;
  }
`

export const rentals__top = css`
    display: flex;
    justify-content:　space-between;
    background-color: #F0F3F5;
    height: 34px;
    padding: 8px;
    align-items: center;
`

export const rentals__title = css`
    font-size: 1.5rem;
    font-weight: bold;
    color: ${palette.text.primary};
    margin-left: 10px;
`

export const rentals__wrapper = css`
    margin: 0 8px 10px 8px;

    @media (max-width: ${breakpoints.tablet}px) {
      // phone
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      border: 1px solid #F0F3F5;
    }
`

export const rental__item__wrapper = css`
    width: 180px;
    padding: 20px;
`

export const rental__item = css`
    font-size: 1.6rem;
    justify-content: center;
    white-space: pre-wrap;
`

export const rental__remarks = css`
    font-size: 1.3rem;
    font-weight: normal;
    color: ${palette.text.primary};
`

export const rental__price = css`
    display: inline-block;
    width: 140px;
    font-size: 1.8rem;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const rental__container = css`
    display: flex;
    margin: 0 24px;
    @media (min-width: ${breakpoints.tablet}px) {
        display: none;
    }
    :not(:last-child) {
        border-bottom: 1px dashed #EFEFEF;
    }
`

export const rental__container__sp = css`
    display: flex;
    margin: 0 24px;
    @media (max-width: ${breakpoints.tablet}px) {
        display: none;
    }
    :not(:last-child) {
        border-bottom: 1px dashed #EFEFEF;
    }
`

export const table__container = css`
    padding: 24px;
`

export const table__top = css`
    display: flex;
    justify-content: flex-end;
`

export const table__top__content = css`
    width: 40%;
`

export const table__item = css`
    display: flex;
    padding: 14px 0;
`

export const table__item__border = css`
  border-bottom: 1px dashed #EFEFEF;
`

export const table__item__Age = css`
    font-size: 1.6rem;
    font-weight: 700;
`

export const table__item__target = css`
    font-size: 1.3rem;
    font-weight: normal;

    @media (max-width: ${breakpoints.pc}px) {
      // tablet
      margin-left: 30px;
    }

    @media (max-width: ${breakpoints.tablet}px) {
      // tablet
      margin-left: 0;
    }
`

export const table__item__price = css`
    font-weight: normal;
    font-size:1.73rem;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const table__item__price_segment = css`
    font-size:1.3rem;
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const table__item__wrapper = css`
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const table__item__target__wrapper = css`
    text-align: center;
    width: 40%;

    @media (max-width: ${breakpoints.pc}px) {
      // tablet
      display: flex;
      align-items: center;
    }

    @media (max-width: ${breakpoints.tablet}px) {
      // phone
      display: initial;
      width: 45%;
    }
`
export const table__item__exception = css`
    font-size: 13px;
    font-weight: normal;
    padding-top: 24px;
    white-space: pre-wrap;
`

export const under__text__wrapper = css`
    text-align: right;
    margin-top: 20px;
`

export const under__text = css`
    font-size: 1.4rem;
    font-weight: normal;
`

export const fees__svg = css`
    width:  21px;
    margin-left: 14px;
    @media (max-width: ${breakpoints.tablet}px) {
        margin-left: 14px;
    }
`

export const detail__button = css`
    width: 26px;
    height: 10px;
`

export const detail__button__container = css`
    margin-right: 0;
    margin-left: auto;

    @media (min-width: ${breakpoints.tablet}px) {
        display: none;
    }
`
