import React from 'react'
import { Link } from 'gatsby'

import Typography from '~/components/atoms/Typography'
import { Container } from '~/components/layout/Container'
import ButtonS from '~/components/molecules/ButtonS'
import {
  button,
  share___button__wrapper,
  pc_display_none,
} from './styles'

import { BathhouseType } from 'types'
export type Props = {
  data: BathhouseType
}

const OfficialSites: React.VFC<Props> = ({ data }) => {
  return(
    <div css={pc_display_none}>
      <div css={share___button__wrapper}>
        {data.site.twitter &&
          <a href={data.site.twitter} target="_blank" rel="noopener noreferrer">
            <ButtonS css={button}>公式Twitter</ButtonS>
          </a>
        }
        {data.site.web &&
          <a href={data.site.web} target="_blank" rel="noopener noreferrer">
            <ButtonS css={button}>公式HP</ButtonS>
          </a>
        }
      </div>
    </div>
  )
}

export default OfficialSites
