import React, { useRef } from 'react'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { breakpoints } from '~/utils/styles'
import AllowLeftIcon from '~/components/molecules/AllowLeftIcon'
import AllowRightIcon from '~/components/molecules/AllowRightIcon'
import Typography from '~/components/atoms/Typography'
import {
  allows_container,
  allow_button,
  allow_icon,
  slide_item,
  image,
  caption_tile,
  caption_typography
} from './styles'
import DEFAULT_SENTO from '~/images/sento/default.jpg'


export type Props = {
  images: Array<{
    url: string
    caption: string
  }>
}

const TopSlideshow: React.VFC<Props> = ({ images }) => {

  const ref = useRef<Slider | null>(null)

  const CustomDots = () => (
    <div css={allows_container}>
      <div css={allow_button} onClick={handlePrevContent}>
        <AllowLeftIcon css={[allow_icon]}/>
      </div>
      <div css={allow_button} onClick={handleNextContent}>
        <AllowRightIcon css={[allow_icon]}/>
      </div>
    </div>
  )

  const handleNextContent = () => {
    ref?.current?.slickNext()
  }

  const handlePrevContent = () => {
    ref?.current?.slickPrev()
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: CustomDots,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,

    // pc
    centerMode: true,
    centerPadding: '100px',
    responsive: [
      {
        // tablet
        breakpoint: breakpoints.pc,
        settings: {
          centerMode: false,
        },
      },
    ],
  }

  const SlideItem = ({ url, caption, index, quantity }) => (
    <div css={slide_item}>
      <img src={url} css={image}/>
      <div css={caption_tile}>
        <Typography css={caption_typography} >
          {index+1}/{quantity} {caption}
        </Typography>
      </div>
    </div>
  )

  const SlideItemNone = ({ url }) => (
    <div css={slide_item}>
      <img src={url} css={image}/>
      <div css={caption_tile}>
        <Typography css={caption_typography} >
          1/1 実際の店舗の写真ではありません。 情報のご提供をお待ちしております。
        </Typography>
      </div>
    </div>
  )

  // 画像が存在しない場合表示しない
  if (!images.length) {
    return (
      <Slider {...settings} ref={ref}>
        <SlideItemNone url={DEFAULT_SENTO} />
      </Slider>
    )
  }

  return (
      <Slider {...settings} ref={ref}>
        {images.map((image, index) => {
          return <SlideItem key={index} url={image.url} caption={image.caption} index={index} quantity={images.length} />
        })}
      </Slider>
  )
}

export default TopSlideshow
