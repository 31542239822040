import React from 'react'
import { Link } from 'gatsby'

import Typography from '~/components/atoms/Typography'
import { Container } from '~/components/layout/Container'
import ButtonS from '~/components/molecules/ButtonS'
import TopSlideshow from '~/components/domain/sento/TopSlideshow'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import SENTO_TWITTER from '~/images/sento/twitter.svg'
import SENTO_FACEBOOK from '~/images/sento/facebook.svg'
import SENTO_TIME from '~/images/sento/time.svg'
import SENTO_ADDRESS from '~/images/sento/address.svg'
import MAP from '~/images/sento/map.jpg'
import ALLOW_DOWN from '~/images/sento/allow-down.svg'

import {
    address__text,
    button,
    name,
    open__time,
    ruby,
    share___button,
    share___button__wrapper,
    info__wrapper,
    tag__wrapper,
    grad__tag,
    normal__tag,
    info,
    info__scroll,
    info__scroll__text,
    info__scroll__allow__down,
    span,
    info__container,
    inquiry,
    tag__container,
    sns__svg,
    info__svg__wrapper,
    info__svg,
    share,
    map__img,
    map__container,
    map__button,
    hours,
    map__container__sp,
    info__wrapper__sp,
    primary__color,
    pc_display_none,
    sp_display_none
} from './styles'

import { BathhouseType } from 'types'
export type Props = {
    data: BathhouseType
}

const SentoHeader: React.VFC<Props> = ({ data }) => {

    const images = data.images.map((imageUrl, index) => {
        return {
            url: imageUrl,
            caption: data.imagesCaption[index],
        }
    })

    return(
      <>
          <Container>
              <div css={tag__wrapper}>
                  <div css={tag__container}>
                      {data.category &&
                      <div css={normal__tag}>{data.category}</div>
                      }
                      {data.genderSelection &&
                      <div css={grad__tag}>{data.genderSelection}</div>
                      }
                  </div>
                  <Typography　variant='h6' component='h3' css={inquiry}>
                      ページの情報更新依頼・新規掲載は
                      <Link target="_blank" to="https://tally.so/r/mVaJEn">
                          <span css={span}>こちら</span>
                      </Link>
                  </Typography>
              </div>
              <div css={info__container}>
                  <div>
                      <Typography variant='h4' component='h3' color='textSecondary' css ={address__text}>{data.address.prefecture}{data.address.city}</Typography>
                      <Typography variant='h2' component='h1' color='textPrimary' css={name}>{data.name}</Typography>
                      <Typography color='textSecondary' css={ruby}>{data.nameKana}</Typography>
                      <div css={[info__wrapper, sp_display_none]}>
                          <div css={info}>
                              <div css={info__svg__wrapper}>
                                  <img src={SENTO_TIME} css={info__svg}/>
                              </div>
                              <div css={open__time}>
                                  <Typography color='textPrimary' css={hours}>
                                      {/*<span css={primary__color}>営業中</span>・*/}営業時間：{data.business.openStartAt}〜{data.business.openEndAt}
                                  </Typography>
                              </div>
                          </div>
                          <div css={info}>
                              <div css={info__svg__wrapper}>
                                  <img src={SENTO_ADDRESS} css={info__svg}/>
                              </div>
                              <div css={open__time}>
                                  <Typography component='p' color='textPrimary' css={hours}>
                                      {data.address.prefecture}{data.address.city}{data.address.townVillage}{data.address.houseNumber}{" "}{data.address.apartmentName}
                                  </Typography>
                              </div>
                          </div>
                      </div>
                  </div>
                  {data.address.mapUrl &&
                    <div css={map__container}>
                        <a href={data.address.mapUrl} target="_blank" rel="noopener noreferrer">
                            <img src={MAP} css={map__img} />
                            <div css={map__button}>地図</div>
                        </a>
                    </div>
                  }
              </div>
              <div css={share___button__wrapper}>
                  {data.site.twitter &&
                  <a href={data.site.twitter} target="_blank" rel="noopener noreferrer">
                      <ButtonS css={button}>公式Twitter</ButtonS>
                  </a>
                  }
                  {data.site.web &&
                  <a href={data.site.web} target="_blank" rel="noopener noreferrer">
                      <ButtonS css={button}>公式HP</ButtonS>
                  </a>
                  }
                  <ButtonS css={share___button}>
                      <a
                        href={`https://twitter.com/intent/tweet?text=${data.name}%0A営業時間：${data.business.openStartAt}〜${data.business.openEndAt}%0A定休日：${String(data.business.closeDay).replace(/,/g,"、")}%0Ahttps://on-sen.jp/sento/${String(data.id)}%0A%23Onsen`}
                        target="_blank"
                        rel="noopener noreferrer"
                        css={share}
                      >
                          <img src={SENTO_TWITTER} css={sns__svg}/>
                          <p>ツイートする</p>
                      </a>
                  </ButtonS>
                  <ButtonS css={share___button}>
                      <a
                        href={`http://www.facebook.com/share.php?u=https://on-sen.jp/sento/` + data.id}
                        target="_blank"
                        rel="noopener noreferrer"
                        css={share}
                      >
                          <img src={SENTO_FACEBOOK} css={sns__svg}/>
                          <p>シェアする</p>
                      </a>
                  </ButtonS>
              </div>
          </Container>
          <div>
              <TopSlideshow images={images}/>
          </div>
          <Container css={pc_display_none}>
              <div css={info__wrapper__sp}>
                  <div css={info__wrapper}>
                      <div css={info}>
                          <div css={info__svg__wrapper}>
                              <img src={SENTO_TIME} css={info__svg}/>
                          </div>
                          <div css={open__time}>
                              <Typography color='textPrimary' css={hours}>
                                  {/*<span css={primary__color}>営業中</span>・*/}営業時間：{data.business.openStartAt}〜{data.business.openEndAt}
                              </Typography>
                          </div>
                      </div>
                      <div css={info}>
                          <div css={info__svg__wrapper}>
                              <img src={SENTO_ADDRESS} css={info__svg}/>
                          </div>
                          <div css={open__time}>
                              <Typography component='p' color='textPrimary' css={hours}>
                                  {data.address.prefecture}{data.address.city}{data.address.townVillage}{data.address.houseNumber}{data.address.apartmentName}
                              </Typography>
                          </div>
                      </div>
                  </div>
                  {data.address.mapUrl &&
                    <div css={map__container__sp}>
                        <a href={data.address.mapUrl} target="_blank" rel="noopener noreferrer">
                            <img src={MAP} css={map__img} />
                            <div css={map__button}>地図</div>
                        </a>
                    </div>
                  }
              </div>
              <div css={info__scroll}>
                <span css={primary__color}>
                  <AnchorLink offset={() => 100} href="#basic__information" css={info__scroll__text}>
                    アクセス・施設の基本情報を見る<img src={ALLOW_DOWN} css={info__scroll__allow__down}/>
                  </AnchorLink>
                </span>
              </div>
          </Container>
      </>
    )
}

export default SentoHeader
