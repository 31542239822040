import React from 'react'
import { container } from './styles'

type Props = {
    onClick?: () => void
    children: React.ReactNode
}

const ButtonS: React.VFC<Props> = ({ onClick, children, ...props }) => {
    return (
        <button css={container} onClick={onClick} {...props} >{children}</button>
    )
}

export default ButtonS