import React from "react"
import {useState} from "react"
import SENTO_MARK from '~/images/sento/sento_mark.svg'
import INFO from '~/images/sento/info.svg'
import CLOSE_BUTTON from '~/images/sento/modal-close.svg'
import CHECK from '~/images/sento/check.svg'
import NONE from '~/images/sento/none.svg'
import OPEN_DETAIL from "~/images/sento/open-detail.svg"
import CLOSE_DETAIL from "~/images/sento/close-detail.svg"
import Typography from '~/components/atoms/Typography'
import Modal from 'react-modal'


import {
    pc_display_none,
    sp_display_none,
    sento_mark__svg,
    info__svg,
    water_quality__type,
    water_quality__type__text,
    card__body,
    card__body__section,
    card__body__section__mark,
    card__body__name,
    card__body__place,
    card__body__source__remarks,
    card__body__info__svg,
    check__svg,
    none__svg,
    modal__container,
    modal__list__items,
    modal__list__item,
    modal__list__item__title,
    modal__list__item__data,
    modal__list__item__data__law,
    modal__list__item__data__law__remarks,
    modal__list__item__efficacy,
    modal__list__item__linkUrl,
    modal__list__item__tags,
    modal__list__close__button,
    modal__list__title,
    modal__list__subtitle,
    modal__list__content,
    modal__list__left,
    modal__list__right,
    modal__list__sento_mark__svg,
    modal__list__item__text,
    modal__list__item__link__block,
    modal__list__item__detail__container,
    modal__list__item__detail__text,
    modal__list__item__detail__button,
} from './styles'

Modal.setAppElement("#___gatsby");

const customStyles = {
  overlay : {
    background: 'rgba(0,0,0, .4)',
    overflowY: 'auto',
  },
};

const WaterQualityCard = ({data}) =>{
    const [isOpenDetail, setIsOpenDetail] = useState<boolean>(false);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

    const openModal = () => {
      setIsOpenModal(true)
    }

    const closeModal = () => {
      setIsOpenModal(false)
      setIsOpenDetail(false)
    }

    return(
        <div css={card__body}>
            <div onClick={openModal}>
              <div css={card__body__section}>
                <div css={card__body__section__mark}>
                  <img src={SENTO_MARK} css={sento_mark__svg}/>
                </div>
                <div>
                  {data.section}
                </div>
              </div>
              <div css={card__body__info__svg}>
                <img src={INFO} css={info__svg}/>
              </div>
              <div css={card__body__name}>
                {data.name}
              </div>
              <div>
                {data.tags.map((content, index)=>{
                  return(
                  <div key={index} css={water_quality__type}>
                      <div css={water_quality__type__text}>
                          {content}
                      </div>
                  </div>
                  )
                })}
              </div>
              <div css={card__body__place}>
                場所：{data.place}
              </div>
              <div css={card__body__source__remarks}>
                {data.sourceRemarks}
              </div>
            </div>

            <Modal
              isOpen={isOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              css={modal__container}
              >
                <div css={modal__list__close__button}>
                  <button onClick={closeModal}>
                    <img src={CLOSE_BUTTON}/>
                  </button>
                </div>

                <div css={modal__list__title}>
                  <div css={modal__list__sento_mark__svg}>
                    <img src={SENTO_MARK} css={sento_mark__svg}/>
                  </div>
                  <div>{data.name}</div>
                </div>

                <div css={modal__list__content}>
                  <div css={modal__list__left}>
                    <div css={modal__list__subtitle}>湯質の詳細情報</div>
                    <div css={modal__list__items}>
                      {data.section && 
                        <div css={modal__list__item}>
                          <p css={modal__list__item__title}>区分</p>
                          <p css={modal__list__item__data}>{data.section}</p>
                        </div>
                      }
                      {data.name &&
                        <div css={modal__list__item}>
                          <p css={modal__list__item__title}>源泉名</p>
                          <p css={modal__list__item__data}>{data.name}</p>
                        </div>
                      }
                      {data.quality && 
                        <div css={modal__list__item}>
                          <p css={modal__list__item__title}>泉質</p>
                          <p css={modal__list__item__data}>{data.quality}</p>
                        </div>
                      }
                      <div css={sp_display_none}>
                        <div>
                          {(data.ph > 0) &&
                            <div css={modal__list__item}>
                              <p css={modal__list__item__title}>Ph</p>
                              <p css={modal__list__item__data}>{data.ph}</p>
                            </div> 
                          }
                          <div css={modal__list__item}>
                            <p css={modal__list__item__title}>飲泉</p>
                            <p css={modal__list__item__data}>{data.isDrink ? "可能": "不可"}</p>
                          </div>
                          {(data.temperature > 0) &&
                            <div css={modal__list__item}>
                              <p css={modal__list__item__title}>源泉温度</p>
                              <p css={modal__list__item__data}>{data.temperature}℃</p>
                            </div>
                          }
                          {data.gush && 
                            <div css={modal__list__item}>
                              <p css={modal__list__item__title}>湧出量</p>
                              <p css={modal__list__item__data}>{data.gush}</p>
                            </div>
                          }
                          {data.frying &&
                            <div css={modal__list__item}>
                              <p css={modal__list__item__title}>揚湯量</p>
                              <p css={modal__list__item__data}>{data.frying}</p>
                            </div>
                          }
                          {data.material &&
                            <div css={modal__list__item}>
                              <p css={modal__list__item__title}>溶存物質</p>
                              <p css={modal__list__item__data}>{data.material}</p>
                            </div>
                          }
                          {data.feature &&
                            <div css={modal__list__item}>
                              <p css={modal__list__item__title}>特徴</p>
                              <p css={modal__list__item__data}>{data.feature}</p>
                            </div>
                          }
                          <div css={modal__list__item}>
                            <p css={modal__list__item__title}>温泉法の表示</p>
                            <div css={modal__list__item__data}>
                              <div>
                                <p css={modal__list__item__data__law}>
                                  {data.isWarm ?
                                    <img src={CHECK} css={check__svg}/>
                                    :<img src={NONE} css={none__svg}/>
                                  }
                                  加温
                                </p>
                              </div>
                              <div>
                                <p css={modal__list__item__data__law}>
                                  {data.isWater ?
                                    <img src={CHECK} css={check__svg}/>
                                    :<img src={NONE} css={none__svg}/>
                                  }
                                  加水
                                </p>
                              </div>
                              <div>
                                <p css={modal__list__item__data__law}>
                                  {data.isCirculation ?
                                    <img src={CHECK} css={check__svg}/>
                                    :<img src={NONE} css={none__svg}/>
                                  }
                                  循環
                                </p>
                              </div>
                              <div>
                                <p css={modal__list__item__data__law}>
                                {data.isDisinfection ?
                                  <img src={CHECK} css={check__svg}/>
                                  :<img src={NONE} css={none__svg}/>
                                }
                                  消毒
                                </p>
                              </div>
                              <p css={modal__list__item__data__law__remarks}>{data.remarks}</p>
                            </div>
                          </div>
                          {data.indicationsSpringType &&
                            <div css={modal__list__item}>
                              <p css={modal__list__item__title}>泉質別適応症</p>
                              <p css={modal__list__item__data}>{data.indicationsSpringType}</p>
                            </div>
                          }
                          {data.generalIndications &&
                            <div css={modal__list__item}>
                              <p css={modal__list__item__title}>一般的適応症</p>
                              <p css={modal__list__item__data}>{data.generalIndications}</p>
                            </div>
                          }
                          {data.generalContraindications &&
                            <div css={modal__list__item}>
                              <p css={modal__list__item__title}>一般的禁忌症</p>
                              <p css={modal__list__item__data}>{data.generalContraindications}</p>
                            </div>
                          }
                        </div>
                      </div>

                      <div css={pc_display_none}>
                        {!isOpenDetail &&
                          <div onClick={() => setIsOpenDetail(!isOpenDetail)} css={modal__list__item__detail__container}>
                            <Typography  css={modal__list__item__detail__text}>全て表示</Typography>
                            <div>
                              {isOpenDetail ?
                                <img src={OPEN_DETAIL} css={modal__list__item__detail__button}/>:
                                <img src={CLOSE_DETAIL} css={modal__list__item__detail__button}/>
                              }
                            </div>
                          </div>
                        }

                        {isOpenDetail &&
                          <div>
                            {(data.ph > 0) &&
                              <div css={modal__list__item}>
                                <p css={modal__list__item__title}>Ph</p>
                                <p css={modal__list__item__data}>{data.ph}</p>
                              </div> 
                            }
                            {data.isDrink &&
                              <div css={modal__list__item}>
                                <p css={modal__list__item__title}>飲泉</p>
                                <p css={modal__list__item__data}>{data.isDrink}</p>
                              </div>
                            }
                            {(data.temperature > 0) &&
                              <div css={modal__list__item}>
                                <p css={modal__list__item__title}>源泉温度</p>
                                <p css={modal__list__item__data}>{data.temperature}</p>
                              </div>
                            }
                            {data.gush && 
                              <div css={modal__list__item}>
                                <p css={modal__list__item__title}>湧出量</p>
                                <p css={modal__list__item__data}>{data.gush}</p>
                              </div>
                            }
                            {data.frying &&
                              <div css={modal__list__item}>
                                <p css={modal__list__item__title}>揚湯量</p>
                                <p css={modal__list__item__data}>{data.frying}</p>
                              </div>
                            }
                            {data.material &&
                              <div css={modal__list__item}>
                                <p css={modal__list__item__title}>溶存物質</p>
                                <p css={modal__list__item__data}>{data.material}</p>
                              </div>
                            }
                            {data.feature &&
                              <div css={modal__list__item}>
                                <p css={modal__list__item__title}>特徴</p>
                                <p css={modal__list__item__data}>{data.feature}</p>
                              </div>
                            }
                            <div css={modal__list__item}>
                              <p css={modal__list__item__title}>温泉法の表示</p>
                              <div css={modal__list__item__data}>
                                <div>
                                  <p css={modal__list__item__data__law}>
                                    {data.isWarm ?
                                      <img src={CHECK} css={check__svg}/>
                                      :<img src={NONE} css={none__svg}/>
                                    }
                                    加温
                                  </p>
                                </div>
                                <div>
                                  <p css={modal__list__item__data__law}>
                                    {data.isWater ?
                                      <img src={CHECK} css={check__svg}/>
                                      :<img src={NONE} css={none__svg}/>
                                    }
                                    加水
                                  </p>
                                </div>
                                <div>
                                  <p css={modal__list__item__data__law}>
                                    {data.isCirculation ?
                                      <img src={CHECK} css={check__svg}/>
                                      :<img src={NONE} css={none__svg}/>
                                    }
                                    循環
                                  </p>
                                </div>
                                <div>
                                  <p css={modal__list__item__data__law}>
                                  {data.isDisinfection ?
                                    <img src={CHECK} css={check__svg}/>
                                    :<img src={NONE} css={none__svg}/>
                                  }
                                    消毒
                                  </p>
                                </div>
                                <p css={modal__list__item__data__law__remarks}>{data.remarks}</p>
                              </div>
                            </div>
                            {data.indicationsSpringType &&
                              <div css={modal__list__item}>
                                <p css={modal__list__item__title}>泉質別適応症</p>
                                <p css={modal__list__item__data}>{data.indicationsSpringType}</p>
                              </div>
                            }
                            {data.generalIndications &&
                              <div css={modal__list__item}>
                                <p css={modal__list__item__title}>一般的適応症</p>
                                <p css={modal__list__item__data}>{data.generalIndications}</p>
                              </div>
                            }
                            {data.generalContraindications &&
                              <div css={modal__list__item}>
                                <p css={modal__list__item__title}>一般的禁忌症</p>
                                <p css={modal__list__item__data}>{data.generalContraindications}</p>
                              </div>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </div>

                  <div css={modal__list__right}>
                    <p css={modal__list__subtitle}>効能について</p>
                    <p css={modal__list__item__efficacy}>{data.efficacy}</p>

                    <div css={modal__list__item__tags}>
                      <p css={modal__list__subtitle}>泉質タグ</p>
                    </div>
                    <div>
                      {data.tags.map((content, index)=>{
                        return(
                        <div key={index} css={water_quality__type}>
                            <div css={water_quality__type__text}>
                                {content}
                            </div>
                        </div>
                        )
                      })}
                    </div>

                    <div css={modal__list__item__linkUrl}>
                      <p css={modal__list__subtitle}>リンク</p>
                      <div css={modal__list__item__link__block}>
                        {data.linkURL.map((url, index)=>{
                          return(
                          <div key={index}>
                            <a href={url} target="_blank">
                              <p css={modal__list__item__text}>{data.linkText[index]}</p>
                            </a>
                          </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
            </Modal>
        </div>
    )
}

export default WaterQualityCard
