import { css } from '@emotion/core'

import { breakpoints } from '~/utils/styles'
import { palette } from '~/utils/colors'

export const root = css`
  position: relative;
  margin-bottom: 2.6rem;

  @media (max-width: ${breakpoints.tablet}px) {
    margin-bottom: initial;
  }
`

export const header = css`
  width: 100%;

  // 両端配置・上下中央寄せ
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 13px;
`

export const title = css`
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  letter-spacing: 0.035em;
  line-height: 2.6rem;
  color: ${palette.common.black};

  @media (max-width: ${breakpoints.tablet}px) {
    font-size: 2.0rem;
  }
`

export const notification_div = css`
  width: 100%;
  padding-left: 1px;
`

export const notification_text = css`
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 0.035em;
  line-height: 2.6rem;
  color: ${palette.common.black};

  @media (max-width: ${breakpoints.pc}px) {
    font-size: 1.4rem;
    line-height: 2.6rem;
  }
`

export const notification_text_omit = css`
  max-height: 13rem;
  height: 100%;
  overflow: hidden;
  position: relative;
`

export const update_at = css`
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #A9A9A9;
`

export const expansion_div = css`
  width: 100%;
  height: 80px;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  position: absolute;
  bottom: -2.6rem;

  background: linear-gradient(rgba(255, 255, 255, 0), #FFFFFF);

  @media (max-width: ${breakpoints.pc}px) {
    display: none;
  }
`

export const expansion_text = css`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.6rem;
  color: #3CB7A9;
  cursor: pointer;
`

export const expansion_text_phone = css`
  float: right;
  @media (min-width: ${breakpoints.pc}px) {
    display: none;
  }
`
