import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'


export const no__svg = css`
  width: 31px;
  height: 31px;
`

export const facility_features__title = css`
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  letter-spacing: 0.035em;
  line-height: 2.6rem;
  margin-bottom: 30px;

  @media (max-width: ${breakpoints.tablet}px) {
    // phone
    font-size: 2.0rem;
    margin-bottom: 22px;
  }
`


export const facility_features__wrapper = css`
  line-height: 26px;
  font-weight: 400;
`

export const facility_features__no__image = css`
  width: 133px;
  height: 90px;
  object-fit: cover;

  @media (max-width: ${breakpoints.tablet}px) {
    // phone
    width: 93px;
    height: 93px;
    object-fit: cover;
  }
`

export const facility_features__block = css`
  border: 1px solid #EAEAEA;
  padding: 10px;
  display: flex;
  position: relative;
  :not(:last-child) {
    margin-bottom: 30px;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    // phone
    width: 100%;
  }
`

export const facility_features__block__icon = css`
  position: absolute;
  top: -16px;
  left: -17px;

  @media (max-width: ${breakpoints.tablet}px) {
    // phone
    top: -6px;
    left: 1px;
  }
`

export const facility_features__block__image = css`
  margin-right: 15px;
`

export const facility_features__block__content = css`
    width: 100%;
`

export const facility_features__block__title = css`
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 18px;
  color: #000000;
  border-bottom: 1px dashed #EAEAEA;
  padding-bottom: 10px;
`

export const facility_features__block__body = css`
`

export const facility_features__supplement__text = css`
  font-size: 1.2rem;
`

export const facility_features__block__none__data = css`
  color: ${palette.common.black};
`
