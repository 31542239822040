import { Container } from '@material-ui/core'
import React ,{useState}from 'react'
import Typography from '~/components/atoms/Typography'
import {
    container,
    title,
    list__container,
    table__top,
    table__item,
    table__top__content,
    table__item__Age,
    table__item__target,
    table__item__target__wrapper,
    table__item__price,
    table__item__price_segment,
    table__item__exception,
    table__item__border,
    rentals__top,
    rental__item,
    rental__container,
    rental__price,
    rental__remarks,
    rental__item__wrapper,
    under__text,
    under__text__wrapper,
    rentals__wrapper,
    table__container,
    fees__svg,
    detail__button,
    rentals__title,
    fee__title,
    detail__button__container,
    rental__container__sp,
    table__item__wrapper
} from './styles'
import FEES_ICON from "~/images/sento/fees-icon.svg"
import OPEN_DETAIL from "~/images/sento/open-detail.svg"
import CLOSE_DETAIL from "~/images/sento/close-detail.svg"

import { BathhouseType } from 'types'
export type Props = {
    data: BathhouseType
}

const FeesCard: React.VFC<Props> = ({ data }) => {
    const [isOpenDetail, setIsOpenDetail] = useState<boolean>(true);

    return(
        <div css={container}>
            <div css={list__container}>
                <div css={title}>
                    <Typography color='textPrimary' css={fee__title}>入館料金・入湯料金</Typography>
                    <img src={FEES_ICON} css={fees__svg}/>
                </div>
                <div>
                    <div css={table__container}>
                        {(data.price.adult.holiday > 0 || data.price.middle.holiday > 0 || data.price.child.holiday > 0 )&&
                            <div css={table__top}>
                                <div css={table__top__content}></div>
                                <Typography color='textPrimary' css={table__item__price_segment}>平日</Typography>
                                <Typography color='textPrimary' css={table__item__price_segment}>土日祝</Typography>
                            </div>
                        }
                        {(data.price.adult.holiday > 0 || data.price.adult.weekday > 0) &&
                            <div css={[table__item, table__item__border]}> 
                                <div css={table__item__target__wrapper}>
                                    <Typography color='textPrimary' css={table__item__Age}>大人</Typography>
                                    {data.price.adult.targetAge &&
                                        <Typography css={table__item__target}>( {data.price.adult.targetAge} )</Typography>
                                    }
                                </div>
                                {data.price.adult.holiday ?
                                    <>
                                        <Typography color='textPrimary' css={table__item__price}>{data.price.adult.weekday}円</Typography>
                                        <Typography color='textPrimary' css={table__item__price}>{data.price.adult.holiday}円</Typography>
                                    </>
                                    : 
                                    <div css={table__item__wrapper}>
                                        <Typography color='textPrimary' css={table__item__price}>{data.price.adult.weekday}円</Typography>
                                    </div>
                                }
                            </div>
                        }
                        {(data.price.middle.holiday > 0 || data.price.middle.weekday > 0) &&
                            <div css={[table__item, table__item__border]}>
                                <div css={table__item__target__wrapper}>
                                    <Typography color='textPrimary' css={table__item__Age}>中人</Typography>
                                    {data.price.middle.targetAge &&
                                        <Typography css={table__item__target}>( {data.price.middle.targetAge} )</Typography>
                                    }
                                </div>
                                {data.price.middle.holiday ?
                                    <>
                                        <Typography color='textPrimary' css={table__item__price}>{data.price.middle.weekday}円</Typography>
                                        <Typography color='textPrimary' css={table__item__price}>{data.price.middle.holiday}円</Typography>
                                    </>
                                    : 
                                    <div css={table__item__wrapper}>
                                        <Typography color='textPrimary' css={table__item__price}>{data.price.middle.weekday}円</Typography>
                                    </div>
                                
                                }
                            </div>
                        }
                        {(data.price.child.holiday > 0 || data.price.child.weekday > 0)&&
                            <div css={table__item}>
                                <div css={table__item__target__wrapper}>
                                    <Typography color='textPrimary' css={table__item__Age}>子供</Typography>
                                    {data.price.child.targetAge &&
                                        <Typography css={table__item__target}>( {data.price.child.targetAge} )</Typography>
                                    }
                                </div>
                                {data.price.child.holiday ?
                                    <>
                                        <Typography color='textPrimary' css={table__item__price}>{data.price.child.weekday}円</Typography>
                                        <Typography color='textPrimary' css={table__item__price}>{data.price.child.holiday}円</Typography>
                                    </>
                                    : 
                                    <div css={table__item__wrapper}>
                                        <Typography color='textPrimary' css={table__item__price}>{data.price.child.weekday}円</Typography>
                                    </div>
                                
                                }
                            </div>
                        }
                        {data.price.exception &&
                          <Typography css={table__item__exception}>{data.price.exception}</Typography>
                        }
                    </div>
                    {data.rentals[0] &&
                      <div css={rentals__wrapper}>
                          <div onClick={() => setIsOpenDetail(!isOpenDetail)} css={rentals__top}>
                              <Typography css={rentals__title}>レンタル料金</Typography>
                              <div css={detail__button__container}>
                                  {isOpenDetail ?
                                      <img src={OPEN_DETAIL} css={detail__button}/>:
                                      <img src={CLOSE_DETAIL} css={detail__button}/>
                                  }
                              </div>
                          </div>
                          {isOpenDetail && data.rentals.map((rental, index) => {
                              return(
                                  <div key={index} css={rental__container}>
                                      <div css={rental__item__wrapper}>
                                          <Typography color='textPrimary' key={rental.item} css={rental__item}>
                                              {rental.item.replace(",", "\n")}
                                          </Typography>
                                          {rental.remarks &&
                                              <Typography color='textPrimary' key={rental.remarks} css={rental__remarks}>
                                              ( {rental.remarks} )
                                              </Typography>
                                          }
                                      </div>
                                      <Typography color='textPrimary' key={rental.price} css={rental__price}>
                                          {rental.price}円
                                      </Typography>
                                  </div>
                              )
                          })}
                          {data.rentals.map((rental, index) => {
                              return(
                                  <div key={index} css={rental__container__sp}>
                                      <div css={rental__item__wrapper}>
                                          <Typography color='textPrimary' key={rental.item} css={rental__item}>
                                              {rental.item.replace(",", "\n")}
                                          </Typography>
                                          {rental.remarks &&
                                              <Typography color='textPrimary' key={rental.remarks} css={rental__remarks}>
                                              ( {rental.remarks} )
                                              </Typography>
                                          }
                                      </div>
                                      <Typography color='textPrimary' key={rental.price} css={rental__price}>
                                          {rental.price}円
                                      </Typography>
                                  </div>
                              )
                          })}
                      </div>
                    }
                </div>
            </div>
            <div css={under__text__wrapper}>
              <Typography color='textPrimary' css={under__text}>価格は税込表示です。</Typography>
            </div>
        </div>
    )
}

export default FeesCard
