import React ,{ useState } from 'react'
import Typography from '~/components/atoms/Typography'
import { Link } from 'gatsby'
import {
  basic__information__wrapper,
  basic__information__title,
  basic__information__list,
  basic__information__basic__list__item,
  basic__information__payment__list__item,
  basic__information__link__url,
  basic__information__layout__mb20,
  basic__information__layout__mb20nlc,
  basic__information__layout__mtb20,
  basic__information__price__exception,
  basic__information__map,
  basic__information__map__button,
  basic__information__map__button__text,
  basic__information__map__iframe,
  google__map__text,
  basic__information__map__button_wrapper,
} from './styles'
import { Container } from '@material-ui/core'

import { BathhouseType } from 'types'
export type Props = {
    data: BathhouseType
}

const BasicInformation: React.VFC<Props> = ({ data }) => {
    var googleMapUrl = '';
    if (data.address.longitude && data.address.latitude) {
      googleMapUrl = 'https://maps.google.com/maps?output=embed&q='
        + data.address.longitude
        + ','
        + data.address.latitude
        + '&t=m&hl=ja&z=15';
    }

    return(
      <div id='basic__information' css={basic__information__wrapper}>
        <Typography variant='h4' component='h4' color='textPrimary' css={basic__information__title}>
          施設の基本情報
        </Typography>

        {googleMapUrl &&
          <div css={basic__information__map}>
            <iframe src={googleMapUrl} css={basic__information__map__iframe}></iframe>

            <div css={basic__information__map__button_wrapper}>
              <a href={data.address.mapUrl} target="_blank">
                <div css={basic__information__map__button}>
                  <div css={basic__information__map__button__text}>
                      Google Mapで詳細を見る
                  </div>
                </div>
              </a>
            </div>
          </div>
        }

        <div css={basic__information__list}>
          <div css={basic__information__basic__list__item}>
              住所
          </div>

          <div css={basic__information__layout__mtb20}>
              {data.address.prefecture}
              {data.address.city}
              {data.address.townVillage}
              {data.address.houseNumber}
              {" "}
              {data.address.apartmentName}
          </div>
        </div>

        <div css={basic__information__list}>
          <div css={basic__information__basic__list__item}>
            TEL
          </div>

          <div css={basic__information__layout__mtb20}>
            <a href="tel:">{data.phoneNumber}</a>
          </div>
        </div>

        {data.parking.isParking &&
          <div css={basic__information__list}>
            <div css={basic__information__basic__list__item}>
              パーキング
            </div>

            <div css={basic__information__layout__mtb20}>
              <div>あり{data.parking.price && `(${data.parking.price})`}{data.parking.units > 0 && ` ${data.parking.units}台`}</div>
            </div>
          </div>
        }

        {data.accesses.length !== 0 &&
          <div css={basic__information__list}>
            <div css={basic__information__basic__list__item}>
              アクセス
            </div>

            <div css={basic__information__layout__mtb20}>
              {data.accesses.map((access, index) => {
                return(
                  <div key={index}>{data.accesses[index]}</div>
                )
              })}
            </div>
          </div>
        }

        <div css={basic__information__list}>
          <div css={basic__information__basic__list__item}>
            定休日
          </div>

          <div css={basic__information__layout__mtb20}>
            <div>{data.business.closeDay}</div>
            <div>{data.business.closeException}</div>
          </div>
        </div>

        {(data.site.web
          || data.site.twitter
          || data.site.facebook
          || data.site.instagram
          || data.site.youtube) &&
          <div css={basic__information__list}>
            <div css={basic__information__basic__list__item}>
              リンク
            </div>

            <div css={basic__information__layout__mtb20}>
                {data.site.web &&
                  <div css={basic__information__layout__mb20nlc}>
                    <div>
                      公式HP
                    </div>
                    <div css={basic__information__link__url}>
                      <a href={data.site.web} target="_blank">{data.site.web}</a>
                    </div>
                  </div>
                }

                {data.site.twitter &&
                  <div css={basic__information__layout__mb20nlc}>
                    <div>
                      公式Twitter
                    </div>
                    <div css={basic__information__link__url}>
                      <a href={data.site.twitter} target="_blank">{data.site.twitter}</a>
                    </div>
                  </div>
                }

                {data.site.facebook &&
                  <div>
                    <div>
                      公式Facebook
                    </div>
                    <div css={basic__information__link__url}>
                      <a href={data.site.facebook} target="_blank">{data.site.facebook}</a>
                    </div>
                  </div>
                }

                {data.site.instagram &&
                  <div>
                    <div>
                      公式Instagram
                    </div>
                    <div css={basic__information__link__url}>
                      <a href={data.site.instagram} target="_blank">{data.site.instagram}</a>
                    </div>
                  </div>
                }

                {data.site.youtube &&
                  <div>
                    <div>
                      公式Youtube
                    </div>
                    <div css={basic__information__link__url}>
                      <a href={data.site.youtube} target="_blank">{data.site.youtube}</a>
                    </div>
                  </div>
                }
            </div>
          </div>
        }

        {(data.price.adult.weekday
          || data.price.middle.weekday
          || data.price.child.weekday) ?
          <div css={basic__information__list}>
            <div css={basic__information__basic__list__item}>
              料金
            </div>
            <div css={basic__information__layout__mtb20}>
              {data.price.adult.weekday > 0&&
                <div css={basic__information__layout__mb20nlc}>
                  <div>大人</div>
                  <div>平日：{data.price.adult.weekday.toLocaleString()}円</div>
                  {data.price.adult.holiday > 0 &&
                    <div>土日祝：{data.price.adult.holiday.toLocaleString()}円</div>
                  }
                </div>
              }

              {data.price.middle.weekday > 0 &&
                <div css={basic__information__layout__mb20nlc}>
                  <div>中人</div>
                  <div>平日：{data.price.middle.weekday.toLocaleString()}円</div>
                  {data.price.middle.holiday > 0 &&
                    <div>土日祝：{data.price.middle.holiday.toLocaleString()}円</div>
                  }
                </div>
              }

              {data.price.child.weekday > 0 &&
                <div css={basic__information__layout__mb20nlc}>
                  <div>子供</div>
                  <div>平日：{data.price.child.weekday.toLocaleString()}円</div>
                  {data.price.child.holiday > 0 &&
                    <div>土日祝：{data.price.child.holiday.toLocaleString()}円</div>
                  }
                </div>
              }

              {data.price.exception ?
                <div css={basic__information__price__exception}>
                  {data.price.exception}
                </div>
                :
                ""
              }
            </div>
          </div>
          :
          ""
        }

        {(data.payment.isCreditCard
          || data.payment.isCash
          || data.payment.isNanaco
          || data.payment.isRakuten
          || data.payment.isEdy
          || data.payment.isWaon
          || data.payment.isTrafficCard) &&
          <div css={basic__information__list}>
            <div css={basic__information__basic__list__item}>
              支払い方法
            </div>

            <div css={basic__information__layout__mtb20}>
              {data.payment.isCreditCard &&
                <div>・クレジットカード</div>
              }

              {data.payment.isCash &&
                <div>・現金</div>
              }

              {data.payment.isNanaco
                || data.payment.isRakuten
                || data.payment.isEdy
                || data.payment.isWaon
                || data.payment.isTrafficCard &&
                <div>・電子マネー</div>
              }

              <div css={basic__information__payment__list__item}>
                <div>
                  {data.payment.isNanaco &&
                    <span>Nanaco、</span>
                  }

                  {data.payment.isRakuten &&
                    <span>楽天、</span>
                  }

                  {data.payment.isEdy &&
                    <span>Edy、</span>
                  }

                  {data.payment.isWaon &&
                    <span>WAON、</span>
                  }

                  {data.payment.isTrafficCard &&
                    <span>交通系各種（Suicaなど）</span>
                  }
                </div>
              </div>
            </div>
          </div>
        }
        
        <div css={basic__information__list}>
          <div css={basic__information__basic__list__item}>
            営業時間
          </div>

          <div css={basic__information__layout__mtb20}>
            <div css={basic__information__layout__mb20}>
              <div>
                {data.business.openDay.map((openDay, index) => {
                  return(
                    <div key={index}>
                      {openDay}: {data.business.openStartAt} 〜 {data.business.openEndAt}
                    </div>
                  )
                })}
              </div>
            </div>

            {data.business.openDayException &&
              <div>※{data.business.openDayException}</div>
            }
            {data.business.openException &&
              <div>※{data.business.openException}</div>
            }
          </div>
        </div>
      </div>
    )
}

export default BasicInformation
