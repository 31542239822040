import React from 'react'
import Typography from '~/components/atoms/Typography'
import {
  check__svg,
  cross__svg,
  none__svg,
  amenity__wrapper,
  amenity__title,
  amenity__subtitle,
  amenity__list,
  amenity__gender,
  amenity__paid,
  amenity__paid__status,
  amenity__paid__text,
  amenity__remarks,
} from './styles'

import CHECK from '~/images/sento/check.svg'
import CROSS from '~/images/sento/cross.svg'
import NONE from '~/images/sento/none.svg'

import { pick, omit } from 'lodash'

import { AmenityType, BathhouseType } from 'types'
export type Props = {
  data: BathhouseType
}

export type Gender = {
  gender: '男' | '女' | '男女'
}

type ConditionalWrapperProps = {
  condition: boolean
  wrapper: JSX.Element
  children: React.ReactNode
}
/**
 *
 * 特定の条件の時だけwrapperでラップする。
 *
 * @param {*} {
 *   condition,
 *   wrapper,
 *   children,
 * }
 */
const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children,
}) =>
  condition ? React.cloneElement(wrapper, null, children) : <>{children}</>

type PickByValue<T, V> = Pick<
  T,
  { [K in keyof T]: T[K] extends V ? K : never }[keyof T]
>
type ExtractedAmenities = Partial<
  PickByValue<BathhouseType['amenities'], AmenityType>
>
type StrictPropertyCheck<T, TExpected, TError> = T &
  (Exclude<keyof T, keyof TExpected> extends never ? {} : TError)
/**
 *
 * ExtractedAmenities型以外のプロパティがある場合はコンパイルエラーにする。
 *
 * @template T
 * @param {(T & StrictPropertyCheck<T, ExtractedAmenities, never>)} obj 検査したいオブジェクト
 *
 */
const amenitiesPropertyCheck = <T,>(
  obj: T & StrictPropertyCheck<T, ExtractedAmenities, never>
): void => {}

type GetAmenitiesByGender = {
  (gender: Gender['gender'], data: BathhouseType): ExtractedAmenities
}
/**
 *
 * dataからgenderに対応するアメニティオブジェクトを抽出して返す。
 *
 * @param {*} gender
 * @param {*} data
 * @return {*} 抽出されたアメニティオブジェクト
 */
const getAmenitiesByGender: GetAmenitiesByGender = (gender, data) => {
  const extractedKeys = Object.keys(data.amenities).filter(
    gender === '男' || gender === '女'
      ? key => data.amenities[key].section === gender
      : key =>
          data.amenities[key].section !== '男' &&
          data.amenities[key].section !== '女'
  )
  const amenities = omit(pick(data.amenities, extractedKeys), 'remarks')
  // 余剰プロパティチェック
  amenitiesPropertyCheck(amenities)
  return amenities
}

const amenityTranslationAndDisplayOrder = new Map<
  keyof Omit<BathhouseType['amenities'], 'remarks'>,
  string
>([
  ['shampoo', 'シャンプー'],
  ['conditioner', 'コンディショナー'],
  ['bodySoap', 'ボディソープ'],
  ['nylonFresser', 'ナイロンフレッサー'],
  ['cottonSwab', '綿棒'],
  ['toner', '化粧水'],
  ['emulsion', '乳液'],
  ['faceMask', 'フェイスマスク'],
  ['faceTowel', 'フェイスタオル'],
  ['bathTowel', 'バスタオル'],
  ['brush', 'クシ/ブラシ'],
  ['hairdryer', 'ドライヤー'],
  ['toothbrush', '歯ブラシ'],
  ['makeupRemover', 'メイク落とし'],
])

const Amenity: React.VFC<Props> = ({ data }) => {
  const AmenitiesByGender: React.VFC<Gender> = ({ gender }) => {
    console.log(gender)
    console.log(Array)
    const amenities = getAmenitiesByGender(gender, data)
    return (
      <>
        <Typography
          variant="h5"
          component="h5"
          color="textPrimary"
          css={amenity__subtitle}
        >
          {gender === '男女'
            ? '男女共通'
            : gender === '男'
            ? '男性のみ'
            : gender === '女'
            ? '女性のみ'
            : undefined}
        </Typography>

        <div css={amenity__gender}>
          {/* アメニティリスト表示 ここから*/}
          {Object.keys(amenities).length === 0
            ? '情報なし'
            : (Object.keys(amenities) as (keyof typeof amenities)[])
                .sort(
                  (key1, key2) =>
                    Array.from(
                      amenityTranslationAndDisplayOrder.keys()
                    ).indexOf(key1) -
                    Array.from(
                      amenityTranslationAndDisplayOrder.keys()
                    ).indexOf(key2)
                )
                .map(amenity => (
                  <React.Fragment key={amenity}>
                    {/* 男女共通のアメニティは <div css={amenity__list}></div> でラップする */}
                    <ConditionalWrapper
                      condition={gender === '男女'}
                      wrapper={<div css={amenity__list}></div>}
                    >
                      <div>
                        {/* アメニティが存在する場合のみ <div css={amenity__paid__status}></div> でラップする */}
                        <ConditionalWrapper
                          condition={['男', '女', '男女'].includes(
                            amenities[amenity].section
                          )}
                          wrapper={<div css={amenity__paid__status}></div>}
                        >
                          {/* アメニティ利用状況アイコン */}
                          {amenities[amenity].section === '' ? (
                            <img src={NONE} css={none__svg}></img>
                          ) : amenities[amenity].section === 'なし' ? (
                            <img src={CROSS} css={cross__svg}></img>
                          ) : amenities[amenity].section === gender ? (
                            <img src={CHECK} css={check__svg}></img>
                          ) : (
                            <img src={NONE} css={none__svg}></img>
                          )}
                          {/* アメニティ名 */}
                          {amenityTranslationAndDisplayOrder.get(amenity)}
                          {/* アメニティが存在し、有料の場合表示 */}
                          {['男', '女', '男女'].includes(
                            amenities[amenity].section
                          ) &&
                            amenities[amenity].price === '有料' && (
                              <div css={amenity__paid}>
                                <div css={amenity__paid__text}>有料</div>
                              </div>
                            )}
                        </ConditionalWrapper>
                      </div>
                    </ConditionalWrapper>
                  </React.Fragment>
                ))}
          {/* アメニティリスト表示 ここまで*/}
        </div>
      </>
    )
  }
  return (
    <div css={amenity__wrapper}>
      <Typography
        variant="h4"
        component="h4"
        color="textPrimary"
        css={amenity__title}
      >
        アメニティ
      </Typography>

      <AmenitiesByGender gender="男女"></AmenitiesByGender>

      {Object.values(data.amenities)
        .filter(
          (amenity): amenity is AmenityType =>
            typeof amenity === 'object' && 'section' in amenity
        )
        .some(amenity => amenity.section === '男') && (
        <AmenitiesByGender gender="男"></AmenitiesByGender>
      )}
      {Object.values(data.amenities)
        .filter(
          (amenity): amenity is AmenityType =>
            typeof amenity === 'object' && 'section' in amenity
        )
        .some(amenity => amenity.section === '女') && (
        <AmenitiesByGender gender="女"></AmenitiesByGender>
      )}

      <div css={amenity__remarks}>{data.amenities.remarks}</div>
    </div>
  )
}

export default Amenity
