import React from 'react'
import { Link } from 'gatsby'

import Typography from '~/components/atoms/Typography'
import { Container } from '~/components/layout/Container'
import ButtonS from '~/components/molecules/ButtonS'
import TopSlideshow from '~/components/domain/sento/TopSlideshow'

import SENTO_TWITTER from '~/images/sento/twitter.svg'
import SENTO_FACEBOOK from '~/images/sento/facebook.svg'
import SENTO_LINE from '~/images/sento/line.svg'

import {
  official__sns__share___button,
  official__sns__sns__svg,
  official__sns__share,
  official__sns__wrapper,
  official__sns__inquiry,
  official__sns__span,
  pc_display_none,
} from './styles'

import { BathhouseType } from 'types'
export type Props = {
    data: BathhouseType
}

const OfficialSns: React.VFC<Props> = ({ data }) => {
    return(
      <>
          <div css={pc_display_none}>
            <div css={official__sns__wrapper}>
              <ButtonS css={official__sns__share___button}>
                  <a
                    href={`https://twitter.com/intent/tweet?text=${data.name}%0A営業時間：${data.business.openStartAt}〜${data.business.openEndAt}%0A定休日：${String(data.business.closeDay).replace(/,/g,"、")}%0Ahttps://on-sen.jp/sento/${String(data.id)}%0A%23Onsen`}
                    target="_blank"
                    rel="noopener noreferrer"
                    css={official__sns__share}
                  >
                      <img src={SENTO_TWITTER} css={official__sns__sns__svg}/>
                      <p>ツイートする</p>
                  </a>
              </ButtonS>
              <ButtonS css={official__sns__share___button}>
                  <a
                    href={`http://www.facebook.com/share.php?u=https://on-sen.jp/sento/` + data.id}
                    target="_blank"
                    rel="noopener noreferrer"
                    css={official__sns__share}
                  >
                      <img src={SENTO_FACEBOOK} css={official__sns__sns__svg}/>
                      <p>シェアする</p>
                  </a>
              </ButtonS>
              <ButtonS css={official__sns__share___button}>
                  <a
                    href={`http://line.me/R/msg/text/?https://on-sen.jp/sento/` + data.id}
                    target="_blank"
                    rel="noopener noreferrer"
                    css={official__sns__share}
                  >
                      <img src={SENTO_LINE} css={official__sns__sns__svg}/>
                      <p>送る</p>
                  </a>
              </ButtonS>
            </div>
            <Typography　variant='h6' component='h3' css={official__sns__inquiry}>
                ページの情報更新依頼・新規掲載は
                <Link target="_blank" to="https://tally.so/r/mVaJEn">
                    <span css={official__sns__span}>こちら</span>
                </Link><br/>
                表示画像はGoogle Mapを利用しています。
            </Typography>
          </div>
      </>
    )
}

export default OfficialSns
