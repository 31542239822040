import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const pc_display_none = css`
  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`

export const sp_display_none = css`
  @media (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`

export const check__svg = css`
    width: 15px;
    height: 26px;
    margin-right: 10px;
`

export const none__svg = css`
    width: 14px;
    height: 26px;
    margin-right: 10px;
`

export const sento_mark__svg = css`
    width: 33px;
    height: 33px;
`

export const info__svg = css`
    width: 23px;
    height: 23px;
`

export const water_quality__wrapper = css`
    line-height: 26px;
    font-weight: 400;
`

export const water_quality__type = css`
    display: inline-block;
    line-height: initial;
    text-align: center;
    padding: 0 10px;
    align-items: center;
    font-size: 1rem;
    width: auto;
    height: 18px;
    background: #ffffff;
    border-radius: 3px;
    color: #999999;
    border: 1px solid #C6C6C6;
    margin: 0 6px;
`

export const water_quality__type__text = css`
    margin: 0 auto;
    line-height: 16px;
`

export const card__body = css`
    border: double 6px ${palette.primary.main};
    color: ${palette.common.black};
    padding: 18px;
    text-align: center;
    position: relative;
    & :hover {
      cursor: pointer;
      opacity: 0.7;
    }
    :not(:last-child) {
      margin-bottom: 30px;
    }

    @media (max-width: ${breakpoints.pc}px) {
      // tablet
      width: 500px;
      margin-left: auto;
      margin-right: auto;
    }

    @media (max-width: ${breakpoints.tablet}px) {
      // phone
      width: initial;
    }
`

export const card__body__section = css`
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.8rem;
    border-bottom: 1px solid #EAEAEA;
    padding-bottom: 6px;
`

export const card__body__section__mark = css`
  margin-right: 10px;
`

export const card__body__name = css`
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 4rem;
  margin: 6px 0;
`

export const card__body__place = css`
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin: 10px 0;
`

export const card__body__source__remarks = css`
  font-size: 1.2rem;
  line-height: 2.6rem;
  color: #D62828;
`

export const card__body__info__svg = css`
  position: absolute;
  top: 20px;
  right: 20px;
`


// modal

export const modal__container = css`
  font-weight: 400;
  margin: 110px auto auto auto;
  width: 935px;
  padding: 70px 90px;
  background-color: white;
  border-radius: 24px;
  color: ${palette.common.black};
  outline: none;

  @media (max-width: ${breakpoints.tablet}px) {
    // phone
    padding: 20px 14px;
    width: 100%;
  }

  @media (max-width: ${breakpoints.pc}px) {
    // tablet
    width: 100%;
  }
`

export const modal__list__items = css`
  background-color: #F5F7F9;
  border-radius: 12px;
  padding: 0 22px;
  white-space: pre-wrap;
`

export const modal__list__item = css`
    display: flex;
    align-items: center;
    padding: 20px 0;
    align-items: start;
    :not(:last-child) {
      border-bottom: 1px solid #EEEEEE;
    }
`

export const modal__list__item__title = css`
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.6rem;
  width: 100px;
`

export const modal__list__item__data = css`
  width: 230px;
  line-height: 26px;

  @media (max-width: ${breakpoints.tablet}px) {
      width: 200px;
  }
`

export const modal__list__item__data__law = css`
  width: 230px;
  line-height: 26px;
  display: flex;
`

export const modal__list__item__data__law__remarks = css`
  margin-top: 14px;
`

export const modal__list__item__linkUrl = css`
  margin-top: 30px;
`

export const modal__list__item__tags = css`
  margin-top: 30px;
`

export const modal__list__close__button = css`
  text-align: right;
`

export const modal__list__content = css`
  display: flex;
  flex-wrap: wrap;
`

export const modal__list__left = css`
  width: 414px;
  margin-right: 45px;

  @media (max-width: ${breakpoints.tablet}px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 35px;
  }

  @media (max-width: ${breakpoints.pc}px) {
    // tablet
    width: 100%;
    margin-right: 0;
    margin-bottom: 35px;
  }
`


export const modal__list__right = css`
  width: 296px;

  @media (max-width: ${breakpoints.tablet}px) {
      width: 100%;
  }

  @media (max-width: ${breakpoints.pc}px) {
    // tablet
    width: 100%;
  }
`

export const modal__list__title = css`
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.6rem;
  letter-spacing: 0.035em;
  display: flex;
  align-items: center;
  margin-bottom: 42px;
`

export const modal__list__subtitle = css`
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.6rem;
  letter-spacing: 0.035em;
  margin-bottom: 14px;
`

export const modal__list__sento_mark__svg = css`
  margin-right: 12px;
`

export const modal__list__item__efficacy = css`
  font-size: 1.4rem;
  line-height: 2.6rem;
  white-space: pre-wrap;
  border-top: 1px solid #EEEEEE;
  padding-top: 4px;
`

export const modal__list__item__text = css`
  padding: 0 7px;
  border: 1px solid #D3D3D3;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 1.4rem;
  line-height: 2.6rem;
  color: ${palette.primary.dark};
`

export const modal__list__item__link__block = css`
  margin-top: 15px;

  display: flex;
  flex-wrap: wrap;
  gap: 9px;
`

export const modal__list__item__detail__container = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  cursor: pointer;
`

export const modal__list__item__detail__text = css`
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.6rem;
  color: #3CB7A9;
`

export const modal__list__item__detail__button = css`
    margin-top: 4px;
    width: 26px;
    height: 10px;
`
