import React from 'react'
import Typography from '~/components/atoms/Typography'
import { Link } from 'gatsby'
import {
  sento_mark__svg,
  info__svg,
  water_quality__wrapper,
  water_quality__title,
  water_quality__none__data
} from './styles'
import WaterQualityCard from '~/components/domain/sento/WaterQuality/Card'

import { BathhouseType } from 'types'
export type Props = {
    data: BathhouseType
}

const WaterQuality: React.VFC<Props> = ({ data }) => {
    return (
        <div css={water_quality__wrapper}>
          {data.qualities[0] &&
            <>
              <Typography variant="h4" component="h4" color="textPrimary" css={water_quality__title}>
                この施設の湯質
              </Typography>
              <div>
                {data.qualities.map((item, index) => {
                  return <WaterQualityCard key={index} data={item} />
                })}
              </div>
            </>
          }
        </div>
    )
}

export default WaterQuality
