import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'


export const sento_mark__svg = css`
    width: 33px;
    height: 33px;
`

export const info__svg = css`
    width: 23px;
    height: 23px;
`

export const water_quality__wrapper = css`
    line-height: 26px;
    font-weight: 400;
`

export const water_quality__title = css`
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    letter-spacing: 0.035em;
    line-height: 2.6rem;
    margin-bottom: 30px;

    @media (max-width: ${breakpoints.tablet}px) {
      font-size: 2.0rem;
    }
`
export const water_quality__none__data = css`
  color: ${palette.common.black};
`
