import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const button = css`
  color: ${palette.primary.dark};
  border-color: #D3D3D3;
  font-weight: 400;
  padding: 8px;
  margin: 4px;
  height: 26px;
`

export const share___button__wrapper = css`
  display: flex;
  margin: 16px 0;
`

export const pc_display_none = css`
  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`
