import { css } from '@emotion/core'

import { breakpoints } from '~/utils/styles'

export const main = css`
  margin-top: 72px;
  position: relative;

  @media (max-width: ${breakpoints.pc}px) {
    margin-top: 30px;
  }
`

export const left = css`
  min-width: 524px;
  width: 60%;

  padding-right: 10%;

  @media (max-width: ${breakpoints.pc}px) {
    // tablet
    width: 100%;
    min-width: 400px;

    padding-right: 0;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    // phone
    min-width: 100%;
  }
`

export const right = css`
  min-width: 295px;
  width: 40%;
  position: absolute;
  top: 0;
  right: 0;

  @media (max-width: ${breakpoints.pc}px) {
    position: static;
    width: 100%;
  }
`

export const block = css`
  margin-bottom: 60px;

  @media (max-width: ${breakpoints.tablet}px) {
    // phone
    margin-bottom: 50px;
  }
`

export const notification__message__block = css`
  margin-bottom: 60px;

  @media (max-width: ${breakpoints.tablet}px) {
    // phone
    margin-bottom: 0px;
  }
`

export const basicInformation__block = css`
  margin-bottom: 60px;

  @media (max-width: ${breakpoints.tablet}px) {
    // phone
    margin-bottom: 0px;
  }
`

export const google__map__text = css`
  text-align: right;
  color: #000000;
  font-weight: normal;
  @media (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`