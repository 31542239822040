import React from 'react'
import { PageProps } from 'gatsby'

import { BathhouseType } from '../../../types'

import SEO from '~/utils/seo'

import { Container } from '~/components/layout/Container'
import SentoHeader from '~/components/domain/sento/PageHeader'
import NotificationMessage from '~/components/domain/sento/NotificationMessage'
import BathsInfo from '~/components/domain/sento/BathsInfo'
import FacilityEquipment from '~/components/domain/sento/FacilityEquipment'
import BasicInformation from '~/components/domain/sento/BasicInformation'
import Amenity from '~/components/domain/sento/Amenity'
import WaterQuality from '~/components/domain/sento/WaterQuality'
import FacilityFeatures from '~/components/domain/sento/FacilityFeatures'
import OfficialSites from '~/components/domain/sento/OfficialSites'
import FeesCard from '~/components/domain/sento/FeesCard'
import OfficialSns from '~/components/domain/sento/OfficialSns'

import {
  main,
  left,
  right,
  block,
  notification__message__block,
  basicInformation__block,
  google__map__text,
} from './styles'

type Props = {
  sentoData: BathhouseType
}

const SentoPage = (props: PageProps<{}, Props>) => {
  const data = props.pageContext.sentoData

  const getTitle = (): string => {
    const title = `${data.name}（${data.address.prefecture}${data.address.city}`
    const category = ((data.category) && ` - ${data.category}`)
    const pjName = `） | Onsen*`

    return title + category + pjName
  }

  const getDescription = (): string => {
    const main = `【銭湯・温泉データベース】${data.name}（${data.address.prefecture}${data.address.city}）の銭湯・温泉情報から特徴、写真、地図などをご紹介。`
    const business = ((data.business.openStartAt && data.business.openEndAt) && `営業時間：${data.business.openStartAt}〜${data.business.openEndAt}`)
    const address = ((data.address.prefecture && data.address.city && data.address.townVillage && data.address.houseNumber && data.address.apartmentName)
      && `住所：${data.address.prefecture}${data.address.city}${data.address.townVillage}${data.address.houseNumber}${data.address.apartmentName}`)
    const holiday = ((data.business.closeDay && data.business.closeException) && `定休日：${data.business.closeDay} ${data.business.closeException}`)

    return main + [business, address, holiday].filter(elem => elem).join('、')
  }

  return (
    <>
      <SEO
        title={getTitle()}
        description={getDescription()}
        url={props.location.href}
        image={data.images[0]}
      />
      <SentoHeader data={data} />
      <Container>
        <div css={main}>
          <div css={left}>
            <div css={notification__message__block}>
              <NotificationMessage message={data.message} updateAt={data.updateAt} />
            </div>
            <div>
              <OfficialSites data={data} />
            </div>
          </div>
          <div css={right}>
            <div css={block}>
              <FeesCard data={data} />
            </div>
            <div css={block}>
              <WaterQuality data={data} />
            </div>
          </div>
          <div css={left}>
            <div css={block}>
              <FacilityFeatures data={data} />
            </div>
            {data.isDisplayBathInfo &&
              <div css={block}>
                <BathsInfo manBath={data.man} womanBath={data.woman} services={data.serviceInformations} />
              </div>
            }
            <div css={block}>
              <Amenity data={data} />
            </div>
            <div css={block}>
              <FacilityEquipment data={data} />
            </div>
            <div css={basicInformation__block}>
              <BasicInformation data={data} />
            </div>
            <div css={block}>
              <OfficialSns data={data} />
            </div>
          </div>
        </div>
        <div css={google__map__text}>
          <p>表示画像はGoogle Mapを利用しています。</p>
        </div>
      </Container>
    </>
  )
}

export default SentoPage
