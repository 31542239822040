import { css } from '@emotion/core'
import { palette } from '~/utils/colors'

export const container = css`
	padding: 3px 12px;
	min-height: 32px;
	color: ${palette.primary.main};
	border: 1px solid ${palette.primary.main};
	border-radius: 4px;
	font-weight: bold;
	font-size: 1.4rem;
	line-height: 128%;

	:hover {
		color: ${palette.common.white};
		background-color: ${palette.primary.light};
	}
`