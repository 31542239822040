import React from 'react'
import Typography from '~/components/atoms/Typography'
import { Link } from 'gatsby'
import {
  facility__equipment__title,
  check__svg,
  cross__svg,
  triangle__svg,
  none__svg,
  list__flex_box,
  list__item_text,
  list__item,
  remark,
  facility__equipment__wrapper,
} from './styles'
import { Container } from '@material-ui/core'
import CHECK from '~/images/sento/check.svg'
import CROSS from '~/images/sento/cross.svg'
import TRIANGLE from '~/images/sento/triangle.svg'
import NONE from '~/images/sento/none.svg'

import { BathhouseType } from 'types'

export type Props = {
  data: BathhouseType
}

const FacilityEquipment: React.VFC<Props> = ({ data }) => {

  const FacilityItem = ({ name, status }) => (
    <div css={list__item}>
      {
        status === 'あり' ? <img src={CHECK} css={check__svg} /> :
          status === 'なし' ? <img src={CROSS} css={cross__svg} /> :
            status === '△' ? <img src={TRIANGLE} css={triangle__svg} /> :
              <img src={NONE} css={none__svg} onClick={() => console.log(status)} />
      }
      <p css={list__item_text}>{name}</p>
    </div>
  )

  return (
    <div css={facility__equipment__wrapper}>
      <Typography variant='h4' component='h4' color='textPrimary' css={facility__equipment__title}>施設の設備</Typography>

      <div css={list__flex_box}>
        <FacilityItem name='コインランドリー' status={data.facilities.coinLaundry.status} />
        <FacilityItem name='漫画' status={data.facilities.manga.status} />
        <FacilityItem name='駐車場' status={data.facilities.parking.status} />
        <FacilityItem name='電子マネー' status={data.facilities.electronicMoney.status} />
        <FacilityItem name='Wi-Fi' status={data.facilities.wifi.status} />
        <FacilityItem name='ビン牛乳' status={data.facilities.bottleMilk.status} />
        <FacilityItem name='マッサージチェア' status={data.facilities.massageChair.status} />
        <FacilityItem name='酒類販売' status={data.facilities.liquor.status} />
        <FacilityItem name='ベビーベッド' status={data.facilities.babyBed.status} />
        <FacilityItem name='深夜営業' status={data.facilities.openLate.status} />
        <FacilityItem name='喫煙所' status={data.facilities.smokingArea.status} />
        <FacilityItem name='タトゥー入場' status={data.facilities.tattooAdmission.status} />
        <FacilityItem name='スーツロッカー' status={data.facilities.suitLocker.status} />
        <FacilityItem name='食事' status={data.facilities.meal.status} />
        <FacilityItem name='休憩処' status={data.facilities.restArea.status} />
        <FacilityItem name='定期貸しロッカー' status={data.facilities.regularRentalLocker.status} />
        <FacilityItem name='AED' status={data.facilities.aed.status} />
        <FacilityItem name='携帯電話充電' status={data.facilities.mobilePhoneCharging.status} />
        <FacilityItem name='エステ/マッサージ' status={data.facilities.esthetic.status} />
        <FacilityItem name='ウォーターサーバー' status={data.facilities.waterServer.status} />
      </div>

      <div css={remark}>
        {data.facilities.remarks}
      </div>
    </div>
  )
}

export default FacilityEquipment
