import { css, keyframes } from '@emotion/core'

import { breakpoints } from '~/utils/styles'
import { palette } from '~/utils/colors'

export const root = css`
  width: 100%;
`

export const tabs_element = {
  root: css`
    margin-bottom: 37px;

    height: 40px;

    border: 1px solid #D4D4D4;
    box-sizing: border-box;
    border-radius: 8px;

    display: flex;
    flex-direction: row;

    @media (max-width: ${breakpoints.tablet}px) {
      margin-bottom: 17px;
    }
  `,

  left: css`
    width: 50%;
    position: relative;

    border-radius: 8px 0 0 8px; /* 左上、右上、右下、左下 */

    display: flex;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.8rem;
  `,

  right: css`
    width: 50%;
    position: relative;

    border-radius: 0 8px 8px 0; /* 左上、右上、右下、左下 */

    display: flex;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.8rem;
  `,

  active: css`
    background-color: #464646;
    color: ${palette.common.white};
  `,

  inactive: css`
    background-color: ${palette.common.white};
    color: #464646;

    cursor: pointer;

    transition: 200ms;

    :hover {
      background-color: #464646;
      color: ${palette.common.white};
      opacity: 0.8;
    }
  `,

  click_area: css`
    width: 100%;
    height: 100%;
    position: absolute;
  `,
}

export const bath = css`
  display: flex;
  flex-direction: column;
`

export const row = css`
  display: flex;
  flex-direction: column;
`

export const row_header = {
  root: css`
    margin-top: 10px;
    padding: 0 10px;

    width: 100%;
    height: 60px;

    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    border-top: 1px solid #EFEFEF;

    @media (max-width: ${breakpoints.tablet}px) {
      padding: 0 5px;
      justify-content: space-between;
    }
  `,

  click_area: css`
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
    @media (min-width: ${breakpoints.tablet}px) {
      display: none;
    }
  `,

  close: css`
    margin-bottom: -10px;
  `,

  left: {
    root: css`
      height: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 7px;
    `,

    icon: css`
      width: 25px;
      height: 25px;
    `,

    title: css`
      font-style: normal;
      font-weight: bold;
      font-size: 2.0rem;
      line-height: 2.6rem;

      color: ${palette.common.black};

      @media (max-width: ${breakpoints.tablet}px) {
        font-size: 1.6rem;
      }
    `,

    sub_info: css`
      font-style: normal;
      font-weight: normal;
      font-size: 1.2rem;
      line-height: 2.6rem;
      letter-spacing: 0.035em;

      color: #3CB7A9;
    `,
  },

  right: {
    root: css`
      height: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 7px;
    `,

    elements_quantity: css`
      font-style: normal;
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 2.6rem;

      color: ${palette.common.black};
    `,

    down_arrow_icon: {
      root: css`
        width: 26px;
        height: 10px;
        z-index: -1;

        transform: rotate(0deg);
        transition: transform 300ms;

        @media (min-width: ${breakpoints.tablet}px) {
          display: none;
        }
      `,
      close: css`
        transform: rotate(180deg);
      `,
    },
  },
}

export const bath_main = {
  root: css`
    padding-left: 35px;
    padding-right: 20px;

    @media (max-width: ${breakpoints.tablet}px) {
      padding-left: 20px;
      padding-right: 15px;
    }
  `,

  kinds: {
    root: css`
      display: flex;
      flex-wrap: wrap;
    `,

    kind: {
      root: css`
        width: 50%;
        min-width: 157px;

        display: flex;
        flex-direction: row;
        gap: 10px;
      `,
      icon: css`
        width: 15px;
        height: 26px;
      `,
      text: css`
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 2.6rem;

        color: ${palette.common.black};
      `,
    },
  },

  features: {
    root: css`
      margin-top: 10px;

      padding-left: 9px;
      padding-top: 8px;
      padding-bottom: 7px;

      border: 1px solid ${palette.primary.main};
      box-sizing: border-box;
      border-radius: 3px;

      @media (max-width: ${breakpoints.tablet}px) {
        margin-top: 6px;
        margin-bottom: 7px;
      }
    `,
    text: css`
      font-style: normal;
      font-weight: normal;
      font-size: 1.3rem;
      line-height: 2.0rem;

      color: ${palette.common.black};
    `,
    dot: css`
      color: ${palette.primary.main};
    `,
  },

  remarks: css`
    margin-top: 15px;

    font-style: normal;
    font-weight: normal;
    font-size: 1.3rem;
    line-height: 2.0rem;

    color: ${palette.common.black};
  `,
}

export const sauna_main = {
  root: css`
    margin-top: 10px;

    padding-left: 35px;
    padding-right: 20px;

    display: flex;
    flex-direction: column;
    gap: 15px;

    @media (max-width: ${breakpoints.tablet}px) {
      // phone
      padding-left: 20px;
      padding-right: 15px;
    }
  `,

  row: {
    root: css`
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 3px;

      border: 1px solid #E8E8E8;
      box-sizing: border-box;
      border-radius: 4px;

      padding: 13px 22px 12px 14px;

      @media (max-width: ${breakpoints.tablet}px) {
        // phone
        padding: 11px 19px 9px 14px;
      }
    `,

    header: {
      root: css`
        height: 28px;

        display: flex;
        justify-content: space-between;
      `,
      left: {
        root: css`
          display: flex;
          flex-direction: row;
          gap: 5px;
        `,
        icon: css`
          width: 15px;
          height: 26px;
        `,
        text: css`
          font-style: normal;
          font-weight: bold;
          font-size: 1.4rem;
          line-height: 2.6rem;

          color: ${palette.common.black};
        `,
      },
      right: {
        text: css`
          font-style: normal;
          font-weight: bold;
          font-size: 1.4rem;
          line-height: 2.6rem;

          color: ${palette.common.black};
        `,
        high_temperature: css`
          font-size: 1.8rem;
          color: #CF5345;
        `,
        low_temperature: css`
          font-size: 1.8rem;
          color: #2A58D0;
        `,
      },
    },
    detail: css`
      font-style: normal;
      font-weight: normal;
      font-size: 1.3rem;
      line-height: 2.0rem;

      color: #828282;
    `,
  },

  remarks: css`
    font-style: normal;
    font-weight: normal;
    font-size: 1.3rem;
    line-height: 2.0rem;

    color: ${palette.common.black};
  `,
}

export const service_main = {
  root: css`
    padding-left: 35px;
    padding-right: 20px;

    @media (max-width: ${breakpoints.tablet}px) {
      padding-left: 20px;
      padding-right: 15px;
    }
  `,

  services: {
    root: css`
      display: flex;
      flex-wrap: wrap;
    `,
    service: {
      root: css`
        width: 50%;
        min-width: 157px;

        display: flex;
        flex-direction: row;
        gap: 10px;
      `,
      icon: css`
        width: 15px;
        height: 26px;
      `,
      text: css`
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 2.6rem;

        color: ${palette.common.black};
      `,
    },
  },

  remarks: css`
    margin-top: 15px;

    font-style: normal;
    font-weight: normal;
    font-size: 1.3rem;
    line-height: 2.0rem;

    color: ${palette.common.black};
  `,

  links: {
    root: css`
      margin-top: 15px;

      display: flex;
      flex-wrap: wrap;
      gap: 9px;
    `,
    link: {
      root: css`
        padding: 0 7px;

        border: 1px solid #D3D3D3;
        box-sizing: border-box;
        border-radius: 5px;
      `,

      text: css`
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 26px;

        color: ${palette.primary.dark};
      `,
    },
  },
}

export const accordion_element = {
  root: css`
    @media (max-width: ${breakpoints.tablet}px) {
      overflow: hidden;
    }
  `,

  close: css`
    @media (max-width: ${breakpoints.tablet}px) {
      margin: 0;
      padding: 0;
      max-height: 0;
    }
  `,
}

export const none_data= css`
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: ${palette.common.black};
`
