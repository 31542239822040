import React, { useState } from 'react'

import BATH_ICON from '~/images/sento/bath-icon.svg'
import SAUNA_ICON from '~/images/sento/sauna-icon.svg'
import SERVICE_ICON from '~/images/sento/service-icon.svg'
import BATH_ICON_RED from '~/images/sento/bath-icon-red.svg'
import SAUNA_ICON_RED from '~/images/sento/sauna-icon-red.svg'
import SERVICE_ICON_RED from '~/images/sento/service-icon-red.svg'
import DOWN_ARROW_ICON from '~/images/sento/down-arrow-icon.svg'
import CHECK from '~/images/sento/check.svg'

import { BathInfoType, SaunaType, ServiceInfoType } from '../../../../../types'

import {
  root,
  tabs_element,
  bath,
  row,
  row_header,
  bath_main,
  sauna_main,
  service_main,
  accordion_element,
  none_data
} from './styles'

export type Props = {
  manBath: BathInfoType,
  womanBath: BathInfoType,
  services: ServiceInfoType[]
}

type RowHeaderProps = {
  icon: any
  title: string
  maxTemperature?: number
  minTemperature?: number
  source?: string
  elementsQuantity?: number
  onClick: () => any
  isOpen: boolean
}

type BathMainProps = {
  kinds: string[]
  features: string[]
  remarks: string
  isOpen: boolean
}

type SaunaMainProps = {
  saunas: SaunaType[]
  remarks: string
  isOpen: boolean
}

type ServiceMainProps = {
  services: ServiceInfoType[]
  isOpen: boolean
}

type BathInfoProps = {
  bathInfo: BathInfoType
  services: ServiceInfoType[]
}

const kindsMap = {
  isMainBathtub: '主浴槽',
  isSittingBath: '座風呂',
  isSleepingBath: '寝風呂',
  isSingleBath: '壺湯/一人風呂',
  isJet: 'バイブラ/ジェット',
  isWaterBath: '水風呂',
  isNuruyu: 'ぬる湯',
  isStandingBath: '立ち湯',
  isMedicinalChangeBath: '薬湯・変わり湯',
  washingPlace: '洗い場',
  isElectricBath: '電気風呂',
}

//サウナの設備の温度が40度以上で赤, 40度未満で青
const temperatureThresholds = 40

const BathsInfo: React.VFC<Props> = ({ manBath, womanBath, services }) => {

  const [isManBath, setIsManBath] = useState<boolean>(true)
  const [isOpenIndoor, setIsOpenIndoor] = useState<boolean>(true)
  const [isOpenOutdoor, setIsOpenOutdoor] = useState<boolean>(true)
  const [isOpenSauna, setIsOpenSauna] = useState<boolean>(true)
  const [isOpenService, setIsOpenService] = useState<boolean>(true)
  const [bathIcon, setBathIcon] = useState<string>(BATH_ICON)
  const [saunaIcon, setSaunaIcon] = useState<string>(SAUNA_ICON)
  const [serviceIcon, setServiceIcon] = useState<string>(SERVICE_ICON)

  const getActiveKinds = (data): string[] => {
    let results: string[] = []
    Object.entries(kindsMap).forEach((kind) => {
      const [key, value] = kind
      if (data[key]) {
        if (key === 'washingPlace') {
          results.push(`${value}(${data[key]}席)`)
        } else {
          results.push(kindsMap[key])
        }
      }
    })
    return results
  }

  const getSaunaColorStyle = (temperature: number) => {
    //40.0未満: 青, 40.0以上: 赤
    return (
      temperature >= temperatureThresholds
        ? sauna_main.row.header.right.high_temperature
        : sauna_main.row.header.right.low_temperature
    )
  }

  const RowHeader
    = ({ icon, title, maxTemperature, minTemperature, source, elementsQuantity, onClick, isOpen }: RowHeaderProps) => {
    const temperatureInfo = (maxTemperature || minTemperature) > 0
      && ((maxTemperature && minTemperature)
        ? `${minTemperature}-${maxTemperature}℃` // 最低温度あり,最高温度あり
        :(maxTemperature 
          ? `${maxTemperature}℃` // 最高温度あり, 最低温度なし
          : `${minTemperature}℃` // 最高温度なし, 最低温度あり
        )
      )
    
    const subInfo = (temperatureInfo || source)
      && ((temperatureInfo && source)
        ? `${temperatureInfo},${source}` // 温度あり, 源泉情報あり
        :(temperatureInfo
          ? `${temperatureInfo}` // 温度あり, 源泉情報なし
          : `${source}` // 温度なし, 源泉情報あり
        )
      )

    return (
      <div css={[row_header.root, !isOpen && row_header.close]}>
        <div css={[row_header.click_area]} onClick={onClick}/>
        <div css={row_header.left.root}>
          <img src={icon} css={row_header.left.icon} />
          <p css={row_header.left.title}>{title}</p>
          {subInfo &&
            <p css={row_header.left.sub_info}>{subInfo}</p>
          }
        </div>
        <div css={row_header.right.root}>
          {elementsQuantity > 0 &&
            <p css={row_header.right.elements_quantity}>{elementsQuantity}</p>
          }
          <img
            src={DOWN_ARROW_ICON}
            css={[row_header.right.down_arrow_icon.root, !isOpen && row_header.right.down_arrow_icon.close]}
          />
        </div>
      </div>
    )
  }

  const BathMain = ({ kinds, features, remarks, isOpen }: BathMainProps) => {
    return (
      <div css={[bath_main.root, accordion_element.root, !isOpen && accordion_element.close]}>
        <div css={bath_main.kinds.root}>
          {kinds.length === 0 &&
            <p css={none_data}>情報なし</p>
          }

          {kinds.map((kind, index) => (
            <div css={bath_main.kinds.kind.root} key={index}>
              <img src={CHECK} css={bath_main.kinds.kind.icon} />
              <p css={bath_main.kinds.kind.text}>{kind}</p>
            </div>
          ))}
        </div>
        {features[0] &&
          <div css={bath_main.features.root}>
            {features.map((feature, index) => (
              <p css={bath_main.features.text} key={index}><span css={bath_main.features.dot}>・</span>{feature}</p>
            ))}
          </div>
        }
        <p css={bath_main.remarks}>{remarks}</p>
      </div>
    )
  }

  const SaunaMain = ({ saunas, remarks, isOpen }: SaunaMainProps) => {
    return (
      <div css={[sauna_main.root, accordion_element.root, !isOpen && accordion_element.close]}>
        {saunas.length === 0 &&
          <p css={none_data}>情報なし</p>
        }
        {saunas.map((sauna, index) => {
          const temperatureStyle = getSaunaColorStyle(sauna.temperature)
          return (
            <div css={sauna_main.row.root} key={index}>
              <div css={sauna_main.row.header.root}>

                <div css={sauna_main.row.header.left.root}>
                  <img src={CHECK} css={sauna_main.row.header.left.icon} />
                  <p css={sauna_main.row.header.left.text}>{sauna.name}</p>
                </div>
                {sauna.temperature !== 0 &&
                  <p css={sauna_main.row.header.right.text}>
                    温度 <span css={temperatureStyle}>{sauna.temperature}</span> 度
                  </p>
                }
              </div>
              <p css={sauna_main.row.detail}>{sauna.detail}</p>
            </div>
          )
        })}
        <p css={sauna_main.remarks}>{remarks}</p>
      </div>
    )
  }

  const ServiceMain = ({ services, isOpen }: ServiceMainProps) => {
    return (
      <div css={[service_main.root, accordion_element.root, !isOpen && accordion_element.close]}>
        <div css={service_main.services.root}>
          {services.length === 0 &&
            <p css={none_data}>情報なし</p>
          }
          {services.map((service, index) => (
            <div css={service_main.services.service.root} key={index}>
              <img src={CHECK} css={service_main.services.service.icon} />
              <p css={service_main.services.service.text}>{service.name}</p>
            </div>
          ))}
        </div>
        {services[0] &&
          <p css={service_main.remarks}>これらのオプションについては公式サイトを参照してください。</p>
        }
        <div css={service_main.links.root}>
          {services.map((service, index) =>
            service.link && (
              <a href={service.link} target='_blank' rel='noopener noreferrer' key={index}>
                <div css={service_main.links.link.root}>
                  <p css={service_main.links.link.text}>{service.name}について</p>
                </div>
              </a>
            ))}
        </div>
      </div>
    )
  }

  const BathInfo = ({ bathInfo, services }: BathInfoProps) => (
    <div css={bath}>
      {(kinds.man.indoor.length !== 0 && kinds.woman.indoor.length !== 0) &&
        <div css={row}>
          <RowHeader
            icon={bathIcon}
            title='内湯'
            maxTemperature={bathInfo.indoor.maxTemperature}
            minTemperature={bathInfo.indoor.minTemperature}
            source={bathInfo.indoor.source}
            elementsQuantity={kinds.man.indoor.length}
            onClick={() => setIsOpenIndoor(!isOpenIndoor)}
            isOpen={isOpenIndoor}
          />
          <BathMain
            kinds={kinds.man.indoor}
            features={bathInfo.indoor.features}
            remarks={bathInfo.indoor.remarks}
            isOpen={isOpenIndoor}
          />
        </div>
      }
      {(kinds.man.outdoor.length !== 0 && kinds.woman.outdoor.length !== 0) &&
        <div css={row}>
          <RowHeader
            icon={bathIcon}
            title='露天風呂'
            maxTemperature={bathInfo.outdoor.maxTemperature}
            minTemperature={bathInfo.outdoor.minTemperature}
            source={bathInfo.outdoor.source}
            elementsQuantity={kinds.man.outdoor.length}
            onClick={() => setIsOpenOutdoor(!isOpenOutdoor)}
            isOpen={isOpenOutdoor}
          />
          <BathMain
            kinds={kinds.man.outdoor}
            features={bathInfo.outdoor.features}
            remarks={bathInfo.outdoor.remarks}
            isOpen={isOpenOutdoor}
          />
        </div> 
      }
      {bathInfo.saunas.informations.length !== 0 &&
        <div css={row}>
          <RowHeader
            icon={saunaIcon}
            title='サウナ・ととのい'
            elementsQuantity={bathInfo.saunas.informations.length}
            onClick={() => setIsOpenSauna(!isOpenSauna)}
            isOpen={isOpenSauna}
          />
          <SaunaMain
            saunas={bathInfo.saunas.informations}
            remarks={bathInfo.saunas.remarks}
            isOpen={isOpenSauna}
          />
        </div>
      }
      {services.length !== 0 &&
        <div css={row}>
          <RowHeader
            icon={serviceIcon}
            title='その他のサービス'
            onClick={() => setIsOpenService(!isOpenService)}
            isOpen={isOpenService}
          />
          <ServiceMain
            services={services}
            isOpen={isOpenService}
          />
        </div>
      }
    </div>
  )

  const kinds = {
    man: {
      indoor: getActiveKinds(manBath.indoor),
      outdoor: getActiveKinds(manBath.outdoor),
    },
    woman: {
      indoor: getActiveKinds(womanBath.indoor),
      outdoor: getActiveKinds(womanBath.outdoor),
    },
  }

  return (
    <div css={root}>
      <div css={tabs_element.root}>
        <div css={[tabs_element.left, isManBath ? tabs_element.active : tabs_element.inactive]}>
          {!isManBath &&
          <div css={tabs_element.click_area} onClick={() => [
            setIsManBath(true),
            setBathIcon(BATH_ICON),
            setSaunaIcon(SAUNA_ICON),
            setServiceIcon(SERVICE_ICON)
            ]} />
          }
          <p>男湯</p>
        </div>
        <div css={[tabs_element.right, !isManBath ? tabs_element.active : tabs_element.inactive]}>
          {isManBath &&
          <div css={tabs_element.click_area} onClick={() => [
            setIsManBath(false),
            setBathIcon(BATH_ICON_RED),
            setSaunaIcon(SAUNA_ICON_RED),
            setServiceIcon(SERVICE_ICON_RED)
          ]} />
          }
          <p>女湯</p>
        </div>
      </div>
      <BathInfo
        bathInfo={isManBath ? manBath : womanBath}
        services={services}
      />
    </div>
  )
}

export default BathsInfo
