import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const basic__information__wrapper = css`
    line-height: 26px;
    font-weight: 400;
    color: ${palette.common.black};
`

export const basic__information__title = css`
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    letter-spacing: 0.035em;
    line-height: 2.6rem;
    margin-bottom: 27px;
    @media (max-width: ${breakpoints.tablet}px) {
      font-size: 2.0rem;
    }
`

export const basic__information__list = css`
    display: flex;
    align-items: center;
    width: 100%;
    border-top: 1px solid #EEEEEE;
    align-items: start;

    @media (max-width: ${breakpoints.tablet}px) {
      // phone
      width: initial;
    }
`

export const basic__information__payment__list__item = css`
    display: flex;
    align-items: center;
`

export const basic__information__basic__list__item = css`
    width: 120px;
    margin: 20px 0;
    font-weight: 700;

    @media (max-width: ${breakpoints.tablet}px) {
      // phone
      width: 26%;
    }
`

export const basic__information__layout__mtb20 = css`
    margin: 20px 0;
    width: 380px;

    @media (max-width: ${breakpoints.tablet}px) {
      // phone
      width: 74%;
    }
`

export const basic__information__layout__mb20nlc = css`
    :not(:last-child) {
        margin-bottom: 20px;
    }
`

export const basic__information__layout__mb20 = css`
    margin-bottom: 20px;
`

export const basic__information__link__url = css`
    text-decoration: underline;
    word-wrap: break-word;
`

export const basic__information__map = css`
`

export const basic__information__map__button_wrapper = css`
  @media (max-width: ${breakpoints.tablet}px){
    display: flex;
    justify-content: center;
  }
`

export const basic__information__map__button = css`

  @media (max-width: ${breakpoints.tablet}px) {
    // phone
    width: 335px;
    height: 47px;
    background: #3BB7A5;
    border-radius: 8px;
    font-weight: 700;
    text-align: center;
    border-bottom: 1px solid #EEEEEE;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const basic__information__map__button__text = css`
  color: #3CB7A9;
  font-weight: 700;
  margin-bottom: 20px;

  @media (max-width: ${breakpoints.tablet}px) {
    // phone
    color: #FFFFFF;
    margin-bottom: initial;
  }

`

export const basic__information__map__iframe = css`
  width: 100%;
  height: 390px;
  margin-bottom: 20px;

  @media (max-width: ${breakpoints.tablet}px) {
    // phone
    height: 250px;
  }
`

export const basic__information__price__exception = css`
  white-space: pre-wrap;
`
