import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const check__svg = css`
    width: 15px;
    height: 26px;
    margin-right: 10px;
`

export const cross__svg = css`
    width: 14px;
    height: 26px;
    margin-right: 10px;
    display: inline-block;
`

export const none__svg = css`
  width: 14px;
  height: 26px;
  margin-right: 10px;
  display: inline-block;
`

export const amenity__wrapper = css`
    line-height: 26px;
    font-weight: 400;
    color: ${palette.common.black};
`

export const amenity__title = css`
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    letter-spacing: 0.035em;
    line-height: 2.6rem;
    margin-bottom: 27px;

    @media (max-width: ${breakpoints.tablet}px) {
      font-size: 2.0rem;
    }
`

export const amenity__subtitle = css`
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #EEEEEE;
    clear: both;
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2.6rem;
`

export const amenity__list = css`
  width: 50%;
  float: left;
  @media (max-width: ${breakpoints.tablet}px) {
    // phone
    width: 100%;
  }
`

export const amenity__gender = css`
    display: inline-block;
    margin-bottom: 30px;
`

export const amenity__paid__status = css`
    display: flex;
    align-items: center;
`

export const amenity__paid = css`
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    margin-left: 15px;
    width: 51px;
    height: 20px;
    background: #B78D3B;
    border-radius: 6px;
    color: #ffffff;
`

export const amenity__paid__text = css`
    margin: 0 auto;
`

export const amenity__remarks = css `
  white-space: pre-wrap;
  line-height: 20px;
`
